import { MarquisPattern } from '../../utils/types';
import styled from 'styled-components';
import { useState } from 'react';
import Icon from '@mdi/react';
import { mdiAllInclusive } from '@mdi/js';
import { useEditorContext } from '../../contexts/EditorContext';
import {
  Container,
  TopContainer,
  TopContainerTopRow,
  TopContainerTopRowLeftTitle,
  TopContainerTopRowRightPressable,
  TopContainerTopRowLeftText,
  TopContainerTopRowRightText,
  MidContainer,
  MidContainerLeft,
  MidContainerRight,
  BoldText,
  BottomContainer,
  BottomContainerTouchable,
  BottomContainerTouchableClose,
  BottomContainerTouchableUse,
  BottomContainerTouchableUseText,
} from '../ExpandedStyles';


const patternBlockStripedImage = '/pattern_block_striped.png';
const patternSingleColorImage = '/pattern_single_color.png';
const patternStripedImage = '/pattern_striped.png';

export interface ExpandedPatternProps {
  selectedMarquisPattern: MarquisPattern;
  onClose: () => void;
  marquisPatternChanged: (marquisType: MarquisPattern) => void;
}
export const ExpandedPattern = (props: ExpandedPatternProps) => {
  const { setSelectedPattern } = useEditorContext();
  const [selectedMarquisPattern, setSelectedMarquisPattern] = useState(
    props.selectedMarquisPattern
      ? props.selectedMarquisPattern
      : MarquisPattern.ALL
  );
  const TopRow = () => {
    const text =
      selectedMarquisPattern === MarquisPattern.SINGLE_COLOR
        ? 'Enfärgad'
        : selectedMarquisPattern === MarquisPattern.BLOCK_STRIPED
        ? 'Blockrandig'
        : selectedMarquisPattern === MarquisPattern.STRIPED
        ? 'Randig'
        : 'Alla';
    return (
      <TopContainerTopRow>
        <TopContainerTopRowLeftTitle>
          <TopContainerTopRowLeftText>{text}</TopContainerTopRowLeftText>
        </TopContainerTopRowLeftTitle>
        <TopContainerTopRowRightPressable>
          <TopContainerTopRowRightText>Typ</TopContainerTopRowRightText>
        </TopContainerTopRowRightPressable>
      </TopContainerTopRow>
    );
  };

  const togglePatternValue = () => {
    setSelectedPattern(selectedMarquisPattern);
    props.marquisPatternChanged(selectedMarquisPattern);
  };

  const MarquisTextButton = (p: { marquisPattern: MarquisPattern }) => {
    const { marquisPattern } = p;
    const text =
      marquisPattern === MarquisPattern.SINGLE_COLOR
        ? 'Enfärgad'
        : marquisPattern === MarquisPattern.BLOCK_STRIPED
        ? 'Blockrandig'
        : marquisPattern === MarquisPattern.STRIPED
        ? 'Randig'
        : marquisPattern === MarquisPattern.ALL
        ? 'Alla'
        : '';
    return (
      <TouchableText onClick={() => setSelectedMarquisPattern(marquisPattern)}>
        <BoldText selected={selectedMarquisPattern === marquisPattern}>
          {text}
        </BoldText>
      </TouchableText>
    );
  };

  const Mid = () => {
    return (
      <MidContainer>
        <MidContainerLeft>
          <TouchableImage
            onClick={() =>
              setSelectedMarquisPattern(MarquisPattern.SINGLE_COLOR)
            }
          >
            <Image src={patternSingleColorImage} />
          </TouchableImage>
          <TouchableImage
            onClick={() =>
              setSelectedMarquisPattern(MarquisPattern.BLOCK_STRIPED)
            }
          >
            <Image src={patternBlockStripedImage} />
          </TouchableImage>
          <TouchableImage
            onClick={() => setSelectedMarquisPattern(MarquisPattern.STRIPED)}
          >
            <Image src={patternStripedImage} />
          </TouchableImage>
          <TouchableImage
            onClick={() => setSelectedMarquisPattern(MarquisPattern.ALL)}
          >
            <Icon path={mdiAllInclusive} size={1.5} />
          </TouchableImage>
        </MidContainerLeft>
        <MidContainerRight>
          <MarquisTextButton marquisPattern={MarquisPattern.SINGLE_COLOR} />
          <MarquisTextButton marquisPattern={MarquisPattern.BLOCK_STRIPED} />
          <MarquisTextButton marquisPattern={MarquisPattern.STRIPED} />
          <MarquisTextButton marquisPattern={MarquisPattern.ALL} />
        </MidContainerRight>
      </MidContainer>
    );
  };

  const Bottom = (props: ExpandedPatternProps) => {
    return (
      <BottomContainer>
        <BottomContainerTouchableClose onClick={props.onClose}>
          <p>Stäng</p>
        </BottomContainerTouchableClose>
        <BottomContainerTouchableUse
          onClick={() => togglePatternValue()}
        >
          <BottomContainerTouchableUseText>
            Använd
          </BottomContainerTouchableUseText>
        </BottomContainerTouchableUse>
      </BottomContainer>
    );
  };

  return (
    <Container>
      <TopContainer style={{ display: 'flex', flexDirection: 'column' }}>
        <TopRow />
        <Mid />
      </TopContainer>
      <Bottom {...props} />
    </Container>
  );
};

const TouchableImage = styled.div``;

const Image = styled.img`
  width: 100;
  resize: contain;
`;

const TouchableText = styled.div``;
