import { MarquisType } from "../../utils/types";

interface Props {
  marquisType?: MarquisType;
}
export const CollapsedMarquis = (props: Props) => {
  const { marquisType } = props;
  const text =
    marquisType === MarquisType.ROLL
      ? "Rullgardinsmarkis"
      : marquisType === MarquisType.TERRACE
      ? "Terrassmarkis"
      : "Fönstermarkis";
  return (
    <div>
      <p>{text}</p>
    </div>
  );
};
