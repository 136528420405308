import {
  CollapsedColor,
  CollapsedMarquis,
  CollapsedMarquisCoat,
  CollapsedPattern,
  CollapsedProfile,
  ExpandedColor,
  ExpandedMarquis,
  ExpandedMarquisCoat,
  ExpandedPattern,
  ExpandedProfile,
} from './';
import {
  Colors,
  DrawerMenuItemType,
  MarquisCoat,
  MarquisPattern,
  MarquisProfile,
  MarquisType,
  Product,
} from '../../utils/types';

import Icon from '@mdi/react';
import { ReactElement } from 'react';
import { mdiMenuDown } from '@mdi/js';
import styled from 'styled-components';
import { useAppContext } from '../../contexts/AppContext';

export interface DrawerMenuItemProps {
  onPress: () => void;
  onClose: () => void;
  marquisTypeChanged: (marquisType: MarquisType) => void;
  marquisPatternChanged: (marquisPattern: MarquisPattern) => void;
  productSelected: (product: Product) => void;
  marquisCoatChanged: (marquisType: MarquisCoat) => void;
  marquisProfileChanged: (profile: MarquisProfile) => void;
  type: DrawerMenuItemType;
  visible: boolean;
  expanded: boolean;
  selectedMarquisType: MarquisType;
  selectedMarquisPattern: MarquisPattern;
  selectedMarquisCoat?: MarquisCoat;
  selectedProduct?: Product;
  selectedProfile: MarquisProfile;
  templateMode: boolean;
}

export const DrawerMenuItem = (props: DrawerMenuItemProps): ReactElement => {
  const { products } = useAppContext();

  const getTitle = () => {
    switch (props.type) {
      case DrawerMenuItemType.MARQUIS:
        return 'Typ';
      case DrawerMenuItemType.PATTERN:
        return 'Mönster';
      case DrawerMenuItemType.COLOR:
        return 'Färg';
      case DrawerMenuItemType.MARQUIS_COAT:
        return 'Kappa';
      case DrawerMenuItemType.PROFILE:
        return 'Profil';
    }
  };

  const title = getTitle();

  return props.visible && props.expanded ? (
    getExpandedContent(props)
  ) : props.visible ? (
    <MenuItemContainer
      onClick={() => {
        return props.onPress();
      }}
    >
      {!props.expanded && (
        <>
          <MenuItemImageContainer>
              {getCollapsedContent(props)}
          </MenuItemImageContainer>
          <div
            style={{
              height: 25,
              width: 1,
              backgroundColor: 'rgba(0, 0, 0, 20%)',
            }}
          ></div>
          <MenuItemTitle>
            <MenuItemTitleText>{title}</MenuItemTitleText>
          </MenuItemTitle>
          <Icon
            path={mdiMenuDown}
            size={1.3}
            color="black"
            style={{ display: 'flex', flex: 1 }}
          />
        </>
      )}
    </MenuItemContainer>
  ) : (
    <></>
  );
};

function getCollapsedContent(props: DrawerMenuItemProps) {


  switch (props.type) {
    case DrawerMenuItemType.MARQUIS:
      return <CollapsedMarquis marquisType={props.selectedMarquisType} />;
    case DrawerMenuItemType.PATTERN:
      return (
        <CollapsedPattern selectedPattern={props.selectedMarquisPattern} />
      );
    case DrawerMenuItemType.COLOR:
      return (
        <CollapsedColor
          selectedProduct={props.selectedProduct}
          templateMode={props.templateMode}
        />
      );
    case DrawerMenuItemType.MARQUIS_COAT:
      return (
        <CollapsedMarquisCoat selectedMarquisCoat={props.selectedMarquisCoat} />
      );
    case DrawerMenuItemType.PROFILE:
      return <CollapsedProfile />;
    default:
      break;
  }
}

function getExpandedContent(props: DrawerMenuItemProps) {
  switch (props.type) {
    case DrawerMenuItemType.MARQUIS:
      return (
        <ExpandedMarquis
          onClose={() => props.onClose()}
          selectedMarquisType={props.selectedMarquisType}
          marquisTypeChanged={type => {
            props.marquisTypeChanged(type);
          }}
        />
      );
    case DrawerMenuItemType.PATTERN:
      return (
        <ExpandedPattern
          onClose={props.onClose}
          marquisPatternChanged={props.marquisPatternChanged}
          selectedMarquisPattern={props.selectedMarquisPattern}
        />
      );
    case DrawerMenuItemType.COLOR:
      return (
        <ExpandedColor
          onClose={props.onClose}
          productSelected={props.productSelected}
          selectedProduct={props.selectedProduct}
          marquisPatternFilter={props.selectedMarquisPattern}
        />
      );
    case DrawerMenuItemType.MARQUIS_COAT:
      return (
        <ExpandedMarquisCoat
          onClose={props.onClose}
          marquisCoatChanged={props.marquisCoatChanged}
          selectedMarquisCoat={props.selectedMarquisCoat}
        />
      );
    case DrawerMenuItemType.PROFILE:
      return (
        <ExpandedProfile
          selectedProfile={props.selectedProfile}
          marquisProfileChanged={props.marquisProfileChanged}
          onClose={props.onClose}
        />
      );
    default:
      return <></>;
  }
}

const MenuItemContainer = styled.div`
  height: 40px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
  border-color: #d3d3d3;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const MenuItemImageContainer = styled.div`
  flex: 7;
  align-items: center;
  justify-content: center;
`;

const MenuItemTitle = styled.div`
  color: #212529;
  display: flex;
  flex: 2;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;

const MenuItemTitleText = styled.p`
  font-weight: bold;
`;
