import { Template } from "../utils/types/Project";
import data1 from "./template-data/template-data-1.json";
import data2 from "./template-data/template-data-2.json";
import data3 from "./template-data/template-data-3.json";
import data4 from "./template-data/template-data-4.json";

const image1 = "/template-static-1.jpg?v=2";
const image2 = "/template-static-2.jpg?v=3";
const image3 = "/template-static-3.jpg?v=2";
const image4 = "/template-static-4.jpg?v=2";



const images: Template[] = [
  {
    id: "1",
    image: image1,
    modelData: data1.models,
  },
  {
    id: "2",
    image: image2,
    modelData: data2.models,
  },
  {
    id: "3",
    image: image3,
    modelData: data3.models,
  },
  {
    id: "4",
    image: image4,
    modelData: data4.models,
  },
];
const getImage = (id: string) => {
  return images.find((x) => x.id === id)?.image;
};
const getAbsoluteImage = (id: string) => {
  const relativePath = images.find((x) => x.id === id)?.image;
  return (relativePath) ? new URL(relativePath, window.location.origin).href : null;
};
const getTemplate = (id: string): Template | undefined => {
  return images.find((x) => x.id === id);
};
export { images, getImage, getAbsoluteImage, getTemplate };
