import { Product } from "../../utils/types";
import styled from "styled-components";
import { useAppContext } from '../../contexts/AppContext';

interface Props {
  selectedProduct?: Product;
  templateMode: boolean;
}
export function CollapsedColor(props: Props) {
  const { products, objects } = useAppContext();

  if (props.templateMode) {
    return (
      <Container>
        <Text>{objects[0]?.product?.sku || products[0]!!.sku}</Text>
        <Image alt="fabric" src={objects[0]?.product?.fabric_image || products[0]!!.fabric_image} />
      </Container>
    );
  } else if (props.selectedProduct === undefined) {
    return (
      <Container>
        <Text>{products[0]!!.sku}</Text>
        <Image alt="fabric" src={products[0]!!.fabric_image} />
      </Container>
    );
  } else {
    return (
      <Container>
        <Text>{props.selectedProduct!!.sku}</Text>
        <Image alt="fabric" src={props.selectedProduct!!.fabric_image} />
      </Container>
    );
  }
}

const Container = styled.div`
  justify-content: center;
  position: relative;
`;

const Image = styled.img`
  width: 100px;
  height: 30px;
  align-self: center;
  margin-top: 4px;
  border-radius: 8px;
  object-fit: cover;
`;

const Text = styled.div`
  color: white;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
`;
