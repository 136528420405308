import { useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Plane, useTexture } from '@react-three/drei';
import * as THREE from 'three';

const BackgroundImage = ({ image }: { image: string | string[] | Record<string, string> }) => {
  const { camera } = useThree();
  const meshRef = useRef<THREE.Mesh>(null);
  const [planeSize, setPlaneSize] = useState({ w: 0, h: 0 });
  const texture = useTexture(image); // imageData <-- for test
  //texture.encoding = THREE.sRGBEncoding;
  // Mått för plane

  useFrame(({ camera }) => {
    if (camera instanceof THREE.PerspectiveCamera && texture instanceof THREE.Texture) {
      const camAspect = camera.aspect;

      let imgWidth, imgHeight;
      if (texture.image.width > 2048) {
        imgWidth = 2048;
        const scaleFactor = imgWidth / texture.image.width;
        imgHeight = Math.round(texture.image.height * scaleFactor);
      } else {
        imgWidth = texture.image.width;
        imgHeight = texture.image.height;
      }

      setPlaneSize({
        w: imgWidth,
        h: imgHeight,
      });

      // Beräkna aspektförhållandet för texturen och kamera
      const imageAspect = imgWidth / imgHeight;

      if (imageAspect > camAspect) {
        // Om bildens aspektförhållande är större än kamerans, justera för att fylla vertikalt

        // Använd planet höjd och kamerans aspektförhållande för att beräkna den synliga höjden
        const visibleHeightAtCurrentDistance = planeSize.h * camAspect;
        // Beräkna det nödvändiga avståndet för att planet ska fylla hela höjden av canvasen
        const hFov = 2 * Math.atan(Math.tan((camera.fov * (Math.PI / 180)) / 2) * camAspect); // Omvandla till radianer
        const distance = visibleHeightAtCurrentDistance / (2 * Math.tan(hFov / 2));

        camera.position.z = distance;
      } else {
        // Annars, justera för att fylla horisontellt (som i din ursprungliga kod)

        // Använd planet bredd och kamerans aspektförhållande för att beräkna den synliga bredden
        const visibleWidthAtCurrentDistance = planeSize.w / camAspect;
        // Beräkna det nödvändiga avståndet för att planet ska fylla hela bredden av canvasen
        const vFov = camera.fov * (Math.PI / 180); // Omvandla till radianer
        const distance = visibleWidthAtCurrentDistance / (2 * Math.tan(vFov / 2));
        camera.position.z = distance;
      }
      camera.updateProjectionMatrix();
    } else if (camera instanceof THREE.OrthographicCamera && texture instanceof THREE.Texture) {
      const camAspect = (camera.right - camera.left) / (camera.top - camera.bottom);

      setPlaneSize({
        w: texture.image.width,
        h: texture.image.height,
      });
      if (planeSize.w === 0 || planeSize.h === 0) return;
      // Beräkna aspektförhållandet för texturen och kamera
      const imageAspect = texture.image.width / texture.image.height;

      if (imageAspect > camAspect) {
        // Anpassa kameran för att fylla vertikalt
        const visibleHeight = planeSize.h;
        camera.top = visibleHeight / 2;
        camera.bottom = -visibleHeight / 2;
        camera.left = (-visibleHeight * camAspect) / 2;
        camera.right = (visibleHeight * camAspect) / 2;
      } else {
        // Anpassa kameran för att fylla horisontellt
        const visibleWidth = planeSize.w;
        camera.left = -visibleWidth / 2;
        camera.right = visibleWidth / 2;
        camera.top = visibleWidth / (2 * camAspect);
        camera.bottom = -visibleWidth / (2 * camAspect);
      }

      camera.updateProjectionMatrix();
    }
  });

  return texture ? (
    <Plane ref={meshRef} args={[planeSize.w, planeSize.h]} position={[0, 0, 0]}>
      <meshBasicMaterial attach="material" map={texture as THREE.Texture} />
    </Plane>
  ) : (
    <></>
  );
};

export default BackgroundImage;
