import styled from 'styled-components';
import { memo } from 'react';
import { useEditorContext } from '../../contexts/EditorContext';

const ProductActiveColor = () => {
  const { selectedProduct } = useEditorContext();

  return (
    <ProductActiveColorContainer>
      {selectedProduct && (
        <div
          style={{
            position: 'relative',
            textAlign: 'center',
          }}
        >
          <img
            src={selectedProduct?.fabric_image}
            style={{
              objectFit: 'cover',
              height: '30px',
              width: '120px',
              borderRadius: 8,
            }}
            alt={selectedProduct?.title}
          />
          <p
            style={{
              position: 'absolute',
              margin: 0,
              top: '44%',
              left: '50%',
              width: '100%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            {selectedProduct?.sku}
          </p>
        </div>
      )}
    </ProductActiveColorContainer>
  );
}

const ProductActiveColorContainer = styled.div`
  flex: 0 0 auto;
  width: 120px;
  height: 30px;
  margin-right: auto;
`;

export default memo(ProductActiveColor);
