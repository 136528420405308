import { MarquisCoat } from '../../utils/types';
import styled from 'styled-components';
import {
  Container,
  TopContainer,
  TopContainerTopRow,
  TopContainerTopRowLeftTitle,
  TopContainerTopRowRightPressable,
  TopContainerTopRowLeftText,
  TopContainerTopRowRightText,
  MidContainer,
  MidContainerLeft,
  MidContainerRight,
  BoldText,
  BottomContainer,
  BottomContainerTouchableClose,
  BottomContainerTouchableUse,
  BottomContainerTouchableUseText,
} from '../ExpandedStyles';

const marquisStraitCoat = '/marquis_strait_coat.png';
const marquisNoCoat = '/no_coat.png';
interface Props {
  selectedMarquisCoat?: MarquisCoat;
  onClose: () => void;
  marquisCoatChanged: (marquisType: MarquisCoat) => void;
}
export function ExpandedMarquisCoat(props: Props) {
  const TopRow = (props: Props) => {
    return (
      <TopContainerTopRow>
        <TopContainerTopRowLeftTitle>
          <TopContainerTopRowLeftText>Fönstermarkis</TopContainerTopRowLeftText>
        </TopContainerTopRowLeftTitle>
        <TopContainerTopRowRightPressable>
          <TopContainerTopRowRightText>Kappa</TopContainerTopRowRightText>
        </TopContainerTopRowRightPressable>
      </TopContainerTopRow>
    );
  };

  const MarquisTextButton = (p: { coat: MarquisCoat }) => {
    const { coat } = p;
    const text = coat === MarquisCoat.STRAIT_COAT ? 'Rak' : 'Ingen';
    return (
      <TouchableText onClick={() => props.marquisCoatChanged(coat)}>
        <BoldText selected={props.selectedMarquisCoat === coat}>
          {text}
        </BoldText>
      </TouchableText>
    );
  };

  const Mid = (props: Props) => {
    return (
      <MidContainer>
        <MidContainerLeft>
          <TouchableImage
            onClick={() => props.marquisCoatChanged(MarquisCoat.STRAIT_COAT)}
          >
            <Image src={marquisStraitCoat} />
          </TouchableImage>
          <TouchableImage
            onClick={() => props.marquisCoatChanged(MarquisCoat.NO_COAT)}
          >
            <Image src={marquisNoCoat} />
          </TouchableImage>
        </MidContainerLeft>
        <MidContainerRight>
          <MarquisTextButton coat={MarquisCoat.STRAIT_COAT} />
          <MarquisTextButton coat={MarquisCoat.NO_COAT} />
        </MidContainerRight>
      </MidContainer>
    );
  };

  const Bottom = (props: Props) => {
    return (
      <BottomContainer>
        <BottomContainerTouchableClose onClick={props.onClose}>
          <p>Stäng</p>
        </BottomContainerTouchableClose>
        <BottomContainerTouchableUse onClick={() => props.onClose()}>
          <BottomContainerTouchableUseText>
            Använd
          </BottomContainerTouchableUseText>
        </BottomContainerTouchableUse>
      </BottomContainer>
    );
  };

  return (
    <Container>
      <TopContainer style={{ display: 'flex', flexDirection: 'column' }}>
        <TopRow {...props} />
        <Mid {...props} />
      </TopContainer>
      <Bottom {...props} />
    </Container>
  );
}

const TouchableImage = styled.div`
  align-items: center;
  margin: 12px;
`;

const TouchableText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Image = styled.img`
  width: 120px;
  resize: contain;
`;
