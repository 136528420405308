import React, { useRef, useState } from 'react';
import styled from "styled-components";
import Icon from '@mdi/react';
import { mdiCloseThick } from '@mdi/js';
import IconButton from "./IconButton";

import { Formik, Form, Field, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';

interface Props {
  image: string | null;
  objects: any;
  openOrder: any;
}

export default function ExportModal({
  image,
  objects,
  openOrder,
}: Props) {

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | boolean>(false);
  const [orderComplete, setOrderComplete] = useState<string | boolean>(false);
  
  const [initialValues, setInitialValues] = useState({
    email: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Ogiltig e-postadress").required("Obligatoriskt")
  });

  const handleSubmit = (values: any, { setSubmitting }: { setSubmitting:any}) => {

    const orderData = {
      image: image,
      email: values.email,
      products: objects,
    };

    sendOrder(orderData)
    .then((data) => {
      setSubmitting(false);
      setOrderComplete(data.message || 'Tack! Du får nu en PDF mailad till dig.');
    })
    .catch((error) => {
      setSubmitting(false);
      setSubmitError("Ett fel uppstod! Försök igen.");
    });
  };

  const sendOrder = async (values: any) => {
    return await fetch('https://sandatex.se/wp-json/api/markisguiden/export-pdf', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

  return (
    <OrderModalContainer>
      <Modal>

        <BackButton>
          <IconButton onClick={() => { openOrder(false) }} opacity={100}>
            <Icon color={"white"} size={2} path={mdiCloseThick} />
          </IconButton>
        </BackButton>

        {orderComplete ? (
          <Thanks>{orderComplete}</Thanks>
        ): (
            
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form>
              <h1>Exporta till PDF</h1>
              
                <Row>
                  Fyll i din e-mail adress för att få exporterad PDF. 
                </Row>
                  
                <Row> 
                  <InputWrap>
                    <label htmlFor="email">E-mail:</label>
                    <Field
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      type="text"
                      name="email"
                      invalid={errors.email ? 'true' : 'false'}
                      disabled={isSubmitting}
                    />
                    <ErrorMessage name="email" component="div">
                      {msg => <div className="error">{msg}</div>}  
                    </ErrorMessage>
                  </InputWrap>
                      
                  <SubmitButton type="submit" disabled={isSubmitting}>
                    Exportera
                  </SubmitButton>
                </Row>


              {submitError ? (<SubmitErrorMessage>{submitError}</SubmitErrorMessage>): null}
            </Form>
          )}
        </Formik>

        )}


      </Modal>
    </OrderModalContainer>
  );
}

const Thanks = styled.div`
  font-size: 18px;
`;

const SubmitErrorMessage = styled.div`
  margin-top: 10px;
  font-size: 16px;
  padding: 12px 22px;
  color: red;
`;

const SubmitButton = styled.button`
  margin-top: 26px;
  font-size: 14px;
  padding: 10px 22px;
  background-color: #1a1a1a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #333333;
  }
  &:focus {
    background-color: #4d4d4d;
  }
  &:disabled {
    background-color: #666666;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
`;

const InputWrap = styled.div`
  width: 100%;
  & input, & select {
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px black solid;
    background-color: white;
  }
  & label {
    font-size: 14px;
    text-align: left;
    display: block;
    margin-top: 8px;
    margin-bottom: 2px;
  }
  & .error {
    font-size: 14px;
    display: block;
    margin-top: 4px;
    color: red;
  }
  & input[invalid="true"] {
    background-color: #fce4e4;
    border: none;
    outline: 1px red solid;
  }
`;

const BackButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const Button = styled.a`
  height: 130px;
  margin: 20px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  padding: 10px;
  border: 2px white solid;
  &:hover {
    border: 2px #5A5B5E solid;
  }
  & span {
    color: #5A5B5E;
    font-family: Trenda-Regular;
    font-weight: bold;
    margin-bottom: 10px;
  }
  & ~ & {
    flex: 0 1 auto;
    align-self: auto;
  }
`;

const OrderModalContainer = styled.div`
  z-index: 9999;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Modal = styled.div`
  position: absolute;
  display: flex;

  top: 50%;
  left: 50%;
  padding: 30px;
  min-width: 500px;
  min-height: 300px;
  box-shadow: 0px 0px 30px 15px rgb(0 0 0 / 40%);
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);

  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`;

const Background = styled.div<{ image: string | undefined }>`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: 110%; 
  background-position: center;
  filter: blur(7px);
  overflow: hidden;
`;

