import React, { useState } from 'react';

import { MarquisType } from '../../utils/types';
import styled from 'styled-components';
import {
  Container,
  TopContainer,
  TopContainerTopRow,
  TopContainerTopRowLeftTitle,
  TopContainerTopRowRightPressable,
  TopContainerTopRowLeftText,
  TopContainerTopRowRightText,
  MidContainer,
  MidContainerLeft,
  MidContainerRight,
  BoldText,
  BottomContainer,
  BottomContainerTouchable,
  BottomContainerTouchableClose,
  BottomContainerTouchableUse,
  BottomContainerTouchableUseText,
} from '../ExpandedStyles';

const windowMarquisImage = '/window_marquis.png';
const rollingMarquisImage = '/rolling_marquis.png';
const terraceMarquisImage = '/terrace_marquis.png';

export interface ExpandedMarquisProps {
  selectedMarquisType?: MarquisType;
  onClose: () => void;
  marquisTypeChanged: (marquisType: MarquisType) => void;
}
export const ExpandedMarquis = (props: ExpandedMarquisProps) => {
  const [selectedMarquisType, setSelectedMarquisType] = useState(
    props.selectedMarquisType
  );
  const TopRow = (props: ExpandedMarquisProps) => {
    return (
      <TopContainerTopRow>
        <TopContainerTopRowLeftTitle>
          <TopContainerTopRowLeftText>Fönstermarkis</TopContainerTopRowLeftText>
        </TopContainerTopRowLeftTitle>
        <TopContainerTopRowRightPressable>
          <TopContainerTopRowRightText>Typ</TopContainerTopRowRightText>
        </TopContainerTopRowRightPressable>
      </TopContainerTopRow>
    );
  };

  const MarquisTextButton = (props: { marquisType: MarquisType }) => {
    const { marquisType } = props;
    const text =
      marquisType === MarquisType.ROLL
        ? 'Rullgardinsmarkis'
        : marquisType === MarquisType.TERRACE
        ? 'Terrassmarkis'
        : marquisType === MarquisType.WINDOW
        ? 'Fönstermarkis'
        : '';
    return (
      <TouchableText onClick={() => setSelectedMarquisType(marquisType)}>
        <BoldText selected={selectedMarquisType === marquisType}>
          {text}
        </BoldText>
      </TouchableText>
    );
  };

  const Mid = (props: ExpandedMarquisProps) => {
    return (
      <MidContainer>
        <MidContainerLeft>
          <TouchableImage
            onClick={() => setSelectedMarquisType(MarquisType.WINDOW)}
          >
            <img src={windowMarquisImage} alt="Marquis" />
          </TouchableImage>
          <TouchableImage
            onClick={() => setSelectedMarquisType(MarquisType.TERRACE)}
          >
            <img src={terraceMarquisImage} alt="Marquis" />
          </TouchableImage>
          {/* 
          <TouchableImage
            onClick={() => setSelectedMarquisType(MarquisType.ROLL)}
          >
            <img src={rollingMarquisImage} alt="Marquis" />
          </TouchableImage>
          */}
        </MidContainerLeft>
        <MidContainerRight>
          <MarquisTextButton marquisType={MarquisType.WINDOW} />
          <MarquisTextButton marquisType={MarquisType.TERRACE} />
          {/* <MarquisTextButton marquisType={MarquisType.ROLL} /> */}
        </MidContainerRight>
      </MidContainer>
    );
  };

  const Bottom = (props: ExpandedMarquisProps) => {
    return (
      <BottomContainer>
        <BottomContainerTouchableClose onClick={props.onClose}>
          <p>Stäng</p>
        </BottomContainerTouchableClose>
        <BottomContainerTouchableUse
          onClick={() => props.marquisTypeChanged(selectedMarquisType!!)}
        >
          <BottomContainerTouchableUseText>
            Använd
          </BottomContainerTouchableUseText>
        </BottomContainerTouchableUse>
      </BottomContainer>
    );
  };

  return (
    <Container>
      <TopContainer
        style={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <TopRow {...props} />
        <Mid {...props} />
      </TopContainer>
      <Bottom {...props} />
    </Container>
  );
};

const TouchableImage = styled.div`
  align-items: center;
`;

const TouchableText = styled.div`
  align-items: center;
  justify-content: center;
`;
