import React, { useRef, useState } from 'react';
import styled from "styled-components";
import { images } from "../utils/templateBackgrounds";
import Icon from '@mdi/react';
import { mdiMenuLeft } from '@mdi/js';
import IconButton from "../components/IconButton";
import { fileToBase64 } from "../utils/fileToBase64";

interface Props {
  image: string | undefined;
  callback: any;
}

export default function WebSelectPreset({
  image,
  callback,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showTemplates, setShowTemplates] = useState<boolean>(false);
  const packageJson = require('../../package.json');
  const versionNumber = packageJson.version;

  const handleButtonClick = () => {   
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => { 
    if (e.target.files) {
      const file = await fileToBase64(e.target.files[0]);
      callback(file);
    }
  };

  const handleTemplateClick = (id: string) => {
    callback(id);
  };

  return (
    <WebSelectContainer>
      <WebLogo>
        <img src={`/markisguiden-logo.png`} width={400} />
        <div className="background"></div>
        <AppVersion>Ver. {versionNumber}</AppVersion>
      </WebLogo>

      <WebSelect>
        {showTemplates ? (
          <BackButton>
            <IconButton onClick={() => { setShowTemplates(false) }} opacity={100}>
              <Icon color={"white"} size={3} path={mdiMenuLeft} />
            </IconButton>
          </BackButton>
        ):null}

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/jpeg"
          multiple={false}
          style={{ display: 'none' }}
        />
        {showTemplates ? (
          <>
            {images.map((m) => { 
              return <img key={`btn-m-${m.id}`} onClick={() => handleTemplateClick(m.id)} src={m.image} width="200" style={{ margin: 6, cursor: 'pointer' }} />
            })}
          </>
        ): (
          <>
            <Button href="#" onClick={handleButtonClick}>
              <span>Välj egen bild</span>
              <img src="/take_image.png"/>
            </Button>

            <Button href="#" onClick={() => { setShowTemplates(true) }}>
              <span>Använd mall</span>
              <img src="/use_template_image.png"/>
            </Button>
          </>
        )}

      </WebSelect>
      <Background  image={image} />
    </WebSelectContainer>
  );
}

const BackButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

const Button = styled.a`
  height: 130px;
  margin: 20px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  padding: 10px;
  border: 2px white solid;
  &:hover {
    border: 2px #5A5B5E solid;
  }
  & span {
    color: #5A5B5E;
    font-family: Trenda-Regular;
    font-weight: bold;
    margin-bottom: 10px;
  }
  & ~ & {
    flex: 0 1 auto;
    align-self: auto;
  }
`;

const WebSelectContainer = styled.div`
  z-index: 9999;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const WebLogo = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 50%;
  min-width: 500px;
  height: 80px;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
  padding-top: 20px;

  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  
  & .background {
    z-index: -1;
    position: absolute;
    min-width: 500px;
    wdith: 100%;
    hegiht: 100%;
    background-color: white;
    height: 80px;
    padding: 30px;
    box-shadow: 0px 0px 30px 15px rgb(0 0 0 / 40%);
    border-radius: 0 0 10px 10px;
  }
`;

const WebSelect = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  padding: 30px;
  min-width: 500px;
  height: 240px;
  box-shadow: 0px 0px 30px 15px rgb(0 0 0 / 40%);
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);

  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`;

const Background = styled.div<{ image: string | undefined }>`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: 110%; 
  background-position: center;
  overflow: hidden;
`;

const AppVersion = styled.div`
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  color: grey;
`;
