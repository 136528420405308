import styled from 'styled-components';
import { memo, useEffect, useState } from 'react';
import ProductItem from './productItem';
import { Product, MarquisPattern } from '../../utils/types';
import { useAppContext } from '../../contexts/AppContext';
import { useSearchContext } from '../../contexts/SearchContext';
import { useEditorContext } from '../../contexts/EditorContext';
interface ProductListProps {
  productSelected: (product: Product) => void;
}

const ProductList = (props: ProductListProps) => {
  const { products } = useAppContext();
  const { searchQuery } = useSearchContext();
  const [ps, setPs] = useState<any>([]);
  const { selectedColors, selectedPattern } = useEditorContext();

  useEffect(() => {
    let filteredPs = products;
    if (selectedPattern && selectedPattern != MarquisPattern.ALL)
      filteredPs = filteredPs.filter(x =>
        x.patterns.map(y => y.slug).includes(selectedPattern)
      );
    if (selectedColors && selectedColors.length > 0)
      filteredPs = filteredPs.filter(
        x => x.colors.filter(y => selectedColors.includes(y.id)).length > 0
      );

    if (searchQuery.trim().length > 0)
      filteredPs = filteredPs.filter( p => p.sku.includes(searchQuery) );

    setPs(filteredPs);
  }, [searchQuery, selectedColors, selectedPattern]);

  return (
    <ColumnList>
      {ps.map((item:any) => (
        <ProductItem key={item.id} product={item} productSelected={props.productSelected} />
      ))}
    </ColumnList>
  );
}

const ColumnList = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: auto auto auto auto;
`;

export default memo(ProductList);
