import { Colors, MarquisPattern, Product } from '../../utils/types';
import styled from 'styled-components';
import { useState, memo } from 'react';
import {
  Container,
  ProductContainer,
  TopContainer,
  BottomContainer,
  BottomContainerTouchableClose,
  BottomContainerTouchableUse,
  BottomContainerTouchableUseText,
} from '../ExpandedStyles';
import ProductList from './productList';
import SearchBar from './SearchBar';
import ProductActiveColor from './ProductActiveColor';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { useAppContext } from '../../contexts/AppContext';
import { useEditorContext } from '../../contexts/EditorContext';

interface ExpandedColorProps {
  onClose: () => void;
  productSelected: (product: Product) => void;
  marquisPatternFilter?: MarquisPattern;
  selectedProduct?: Product;
}

const ExpandedColor = memo((props: ExpandedColorProps) => {
  const [showColorFilter, setShowColorFilter] = useState(false);
  const { colors, setObjects, objects } = useAppContext();
  const [openSearchbar, setOpenSearchbar] = useState(false);

  const productSelected = (product: Product | null, target = true, close = true) => {
    if (!product) return;

    if (target) {
      props.productSelected(product);
    } else {
      setObjects((models) =>
        models.map((o) => {
          o.product = product;
          return o;
        })
      );
    }
    if(close) props.onClose();
  };

  function ColorMidContainer(colors: Colors) {
    const { selectedColors, setSelectedColors } = useEditorContext();

    const filterColors = (id: number) => {
      if (selectedColors.length > 0 && selectedColors.includes(id)) {
        setSelectedColors((colors: any) => colors.filter((colorId: number) => colorId !== id) );
      } else {
        setSelectedColors((colors: any) => [...colors, id] );
      }
    };

    return (
      <Container>
        <MidContainerTitleContainer>
          <MidTitle>Filtrera färger</MidTitle>
        </MidContainerTitleContainer>
        <ProductListContainer>
          <ColumnList>
            {colors.items.map(item => {
              return (
                <ColorContainer
                  key={item.id}
                  onClick={() => filterColors(item.id)}
                  selected={selectedColors.includes(item.id)}
                  backgroundColor={item.color}
                >
                  <ColorText color={item.color_label_black ? 'black' : 'white'}>
                    {item.name}
                  </ColorText>
                </ColorContainer>
              );
            })}
          </ColumnList>
        </ProductListContainer>
      </Container>
    );
  }

  function ProductMidContainer() {
    return (
      <ProductContainer>
        <ProductMidContainerTitleContainer>
          {!openSearchbar ? (
            <>
              <ProductActiveColor/>
              <SearchButton onClick={() => setOpenSearchbar(true) }>
                <Icon path={mdiMagnify} size={1} />
              </SearchButton>

              <FilterButton onClick={() => setShowColorFilter(true)}>
                <p>Filter</p>
              </FilterButton>
            </>
          ) : (
              <SearchBar isOpen={setOpenSearchbar} />
          )}
        </ProductMidContainerTitleContainer>

        <ProductListContainer>
          <ProductList productSelected={productSelected} />
        </ProductListContainer>
      </ProductContainer>
    );
  }
  const ButtonContainer = (props: ExpandedColorProps) => {
    const { selectedColors, setSelectedColors } = useEditorContext();
    return (
      <BottomContainer>
        <BottomContainerTouchableClose onClick={props.onClose}>
          <p>Stäng</p>
        </BottomContainerTouchableClose>
        <BottomContainerTouchableClose
          onClick={() => {
            setSelectedColors([]);
            setShowColorFilter(false);
          }}
        >
          <p>Visa alla</p>
        </BottomContainerTouchableClose>
        <BottomContainerTouchableUse onClick={() => setShowColorFilter(false)}>
          <BottomContainerTouchableUseText>
            {`Filtrera (${selectedColors.length})`}
          </BottomContainerTouchableUseText>
        </BottomContainerTouchableUse>
      </BottomContainer>
    );
  };

  const ProductBottomContainer = (props: ExpandedColorProps) => {
    const { selectedProduct } = useEditorContext();
    return (
      <BottomContainer>
        <BottomContainerTouchableClose onClick={props.onClose}>
          <p>Stäng</p>
        </BottomContainerTouchableClose>
        <BottomContainerTouchableUse onClick={() => productSelected(selectedProduct, true)}>
          <BottomContainerTouchableUseText>
            Använd vald
          </BottomContainerTouchableUseText>
        </BottomContainerTouchableUse>
        <BottomContainerTouchableUse onClick={() => productSelected(selectedProduct, false)} style={{ opacity: objects.length>0 ? 1 : 0.5 }}>
          <BottomContainerTouchableUseText>
            Använd alla
          </BottomContainerTouchableUseText>
        </BottomContainerTouchableUse>
      </BottomContainer>
    );
  };

  return (
    <>
      <TopContainer>
        {showColorFilter ? (
          <>{colors && <ColorMidContainer {...colors} />}</>
        ) : (
          <ProductMidContainer />
        )}
      </TopContainer>
      {showColorFilter ? (
        <ButtonContainer {...props} />
      ) : (
        <ProductBottomContainer {...props} />
      )}
    </>
  );
});

const MidContainerTitleContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: center;
`;

const MidTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const ColorContainer = styled.div<{
  backgroundColor: string;
  selected: boolean;
}>`
  background-color: ${props => props.backgroundColor};
  padding: 4px;
  margin: 2px;
  border-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  opacity: ${props => (props.selected ? 0.4 : 1)};
  border: ${props => (props.selected ? '1px solid black' : '1px solid white')};
`;

const ColorText = styled.p<{ color: string }>`
  color: ${props => props.color};
`;
const ProductMidContainerTitleContainer = styled.div`
  width: 86.5%;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProductListContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  padding-right: 10px;
  box-sizing: border-box;
`;

const ColumnList = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: auto auto auto auto;
`;

const SearchButton = styled.button`
  border: 1px black solid;
  border-radius: 8px;
  padding: 3px 5px 2px 5px;
  flex: 0 0 auto;
  margin-right: 10px;
  cursor: pointer;
`;

const FilterButton = styled.div`
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 20px;
  flex: 0 0 auto;
`;

export { ExpandedColor };