import React, { useRef, useState } from 'react';
import styled from "styled-components";
import Icon from '@mdi/react';
import { mdiCloseThick } from '@mdi/js';
import IconButton from "./IconButton";

import { Formik, Form, Field, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';

interface Props {
  image: string | undefined;
  objects: any;
  openOrder: any;
}

export default function OrderModal({
  image,
  objects,
  openOrder,
}: Props) {

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | boolean>(false);
  const [orderComplete, setOrderComplete] = useState<string | boolean>(false);
  
  const [initialValues, setInitialValues] = useState({
    items: [],
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    address_1: "",
    postcode: "",
    city: "",
    country: "SE",
  });


  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-zåäöÅÄÖ]+$/, "Endast bokstäver tillåtna")
      .min(3, "Namnet är för kort")
      .required("Obligatoriskt")
      .matches(/^[A-Za-zåäöÅÄÖ]+$/, "Endast bokstäver tillåtna")
      .min(3, "Namnet är för kort")
      .required("Obligatoriskt"),
    last_name: Yup.string()
      .matches(/^[A-Za-zåäöÅÄÖ]+$/, "Endast bokstäver tillåtna")
      .min(3, "Namnet är för kort")
      .required("Obligatoriskt"),
    address_1: Yup.string()
      .min(5, "Adressen är för kort")
      .required("Obligatoriskt"),
    postcode: Yup.string()
      .min(4, "Postkoden är för kort")
      .required("Obligatoriskt"),
    city: Yup.string()
      .matches(/^[A-Za-zåäöÅÄÖ]+$/, "Staden får bara innehålla bokstäver")
      .min(3, "Namnet är för kort")
      .required("Obligatorisk"),
    email: Yup.string().email("Ogiltig e-postadress").required("Obligatoriskt"),
    phone: Yup.number()
      .typeError("Telefonnummer är obligatoriskt")
      .integer("Telefonnummer måste vara ett heltal")
      .required("Obligatoriskt")
  });

  const handleSubmit = (values: any, { setSubmitting }: { setSubmitting:any}) => {
    const skus = objects.map((object: any) => object.product.sku);
    const items = skus.filter((item: any, index: any) => skus.indexOf(item) === index);

    const orderData = {
      items: items,
      email: values.email,
      phone: values.phone,
      address: {
        first_name: values.first_name,
        last_name: values.last_name,
        address_1: values.address_1,
        postcode: values.postcode,
        city: values.city,
        country: values.country,
      }
    };

    sendOrder(orderData)
    .then((data) => {
      setSubmitting(false);
      setOrderComplete(data.message || 'Tack för din beställning!');
    })
    .catch((error) => {
      setSubmitting(false);
      setSubmitError("Ett fel uppstod! Försök igen.");
    });

    setTimeout(() => {
      console.log('isSubmitting', isSubmitting);
      //setSubmitting(false);
    }, 400);
  };

  const sendOrder = async (values: any) => {
    return await fetch('https://sandatex.se/wp-json/api/markisguiden/order', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

  return (
    <OrderModalContainer>
      <Modal>

        <BackButton>
          <IconButton onClick={() => { openOrder(false) }} opacity={100}>
            <Icon color={"white"} size={2} path={mdiCloseThick} />
          </IconButton>
        </BackButton>

        {orderComplete ? (
          <Thanks>{orderComplete}</Thanks>
        ): (
            
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form>
              <h1>Beställ vävprover kostnadsfritt</h1>
              <Row>
                <InputWrap>
                  <label htmlFor="first_name">Förnamn:</label>
                  <Field
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                    error={
                      touched.first_name && errors.first_name
                    }
                    type="text"
                    name="first_name"
                    invalid={errors.first_name ? 'true' : 'false'}
                    disabled={isSubmitting}
                  />
                  <ErrorMessage name="first_name" component="div">
                    {msg => <div className="error">{msg}</div>}  
                  </ErrorMessage>
                </InputWrap>
                    
                <InputWrap>
                  <label htmlFor="last_name">Efternamn:</label>
                  <Field
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    error={
                      touched.last_name && errors.last_name
                    }
                    type="text"
                    name="last_name"
                    invalid={errors.last_name ? 'true' : 'false'}
                    disabled={isSubmitting}
                  />
                  <ErrorMessage name="last_name" component="div">
                    {msg => <div className="error">{msg}</div>}  
                  </ErrorMessage>
                </InputWrap>
              </Row>    

                  
              <InputWrap>
                <label htmlFor="address_1">Adress:</label>
                <Field
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_1}
                  type="text"
                  name="address_1"
                  invalid={errors.address_1 ? 'true' : 'false'}
                  disabled={isSubmitting}
                />
                <ErrorMessage name="address_1" component="div">
                  {msg => <div className="error">{msg}</div>}  
                </ErrorMessage>
              </InputWrap>

              <Row>
                    
                <InputWrap>
                  <label htmlFor="postcode">Postnummer:</label>
                  <Field
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postcode}
                    type="text"
                    name="postcode"
                    invalid={errors.postcode ? 'true' : 'false'}
                    disabled={isSubmitting}
                  />
                  <ErrorMessage name="postcode" component="div">
                    {msg => <div className="error">{msg}</div>}  
                  </ErrorMessage>
                </InputWrap>

                <InputWrap>
                  <label htmlFor="city">Stad:</label>
                  <Field
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    type="text"
                    name="city"
                    invalid={errors.city ? 'true' : 'false'}
                    disabled={isSubmitting}
                  />
                  <ErrorMessage name="city" component="div">
                    {msg => <div className="error">{msg}</div>}  
                  </ErrorMessage>
                </InputWrap>
              </Row>
                  
              <InputWrap>
                <label htmlFor="country">Land:</label>
                <Field
                  as="select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.country}
                  name="country"
                  disabled={isSubmitting}
                >
                  <option value="SE">Sverige</option>
                  <option value="NO">Norge</option>
                  <option value="DK">Danmark</option>
                  <option value="FI">Finland</option>
                </Field>
              </InputWrap>
                  
                <Row> 
                  <InputWrap>
                    <label htmlFor="email">E-mail:</label>
                    <Field
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      type="text"
                      name="email"
                      invalid={errors.email ? 'true' : 'false'}
                      disabled={isSubmitting}
                    />
                    <ErrorMessage name="email" component="div">
                      {msg => <div className="error">{msg}</div>}  
                    </ErrorMessage>
                  </InputWrap>
                      
                  <InputWrap>
                    <label htmlFor="phone">Telefon:</label>
                    <Field
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      type="text"
                      name="phone"
                      invalid={errors.phone ? 'true' : 'false'}
                      disabled={isSubmitting}
                    />
                    <ErrorMessage name="phone" component="div">
                      {msg => <div className="error">{msg}</div>}  
                    </ErrorMessage>
                  </InputWrap>
                </Row>

              <SubmitButton type="submit" disabled={isSubmitting}>
                Skicka order
              </SubmitButton>
              {submitError ? (<SubmitErrorMessage>{submitError}</SubmitErrorMessage>): null}
            </Form>
          )}
        </Formik>

        )}


      </Modal>
      <Background  image={image} />
    </OrderModalContainer>
  );
}

const Thanks = styled.div`
  font-size: 18px;
`;

const SubmitErrorMessage = styled.div`
  margin-top: 10px;
  font-size: 16px;
  padding: 12px 22px;
  color: red;
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  font-size: 16px;
  padding: 12px 22px;
  background-color: #1a1a1a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #333333;
  }
  &:focus {
    background-color: #4d4d4d;
  }
  &:disabled {
    background-color: #666666;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 12px;
`;

const InputWrap = styled.div`
  & input, & select {
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px black solid;
    background-color: white;
  }
  & label {
    font-size: 14px;
    text-align: left;
    display: block;
    margin-top: 8px;
    margin-bottom: 2px;
  }
  & .error {
    font-size: 14px;
    display: block;
    margin-top: 4px;
    color: red;
  }
  & input[invalid="true"] {
    background-color: #fce4e4;
    border: none;
    outline: 1px red solid;
  }
`;

const BackButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const Button = styled.a`
  height: 130px;
  margin: 20px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  padding: 10px;
  border: 2px white solid;
  &:hover {
    border: 2px #5A5B5E solid;
  }
  & span {
    color: #5A5B5E;
    font-family: Trenda-Regular;
    font-weight: bold;
    margin-bottom: 10px;
  }
  & ~ & {
    flex: 0 1 auto;
    align-self: auto;
  }
`;

const OrderModalContainer = styled.div`
  z-index: 9999;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Modal = styled.div`
  position: absolute;
  display: flex;

  top: 50%;
  left: 50%;
  padding: 30px;
  min-width: 500px;
  min-height: 300px;
  box-shadow: 0px 0px 30px 15px rgb(0 0 0 / 40%);
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);

  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`;

const Background = styled.div<{ image: string | undefined }>`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: 110%; 
  background-position: center;
  filter: blur(7px);
  overflow: hidden;
`;

