import { SliderSettings } from "./types/SliderSettings";

const H: SliderSettings = {
  min: 100,
  max: 750,
  step: 1,
  value: 240,
};

const W: SliderSettings = {
  min: 100,
  max: 1000,
  step: 1,
  value: 200,
};

const X: SliderSettings = {
  min: -80,
  max: 80,
  step: 0.01,
  value: 0,
};

const Y: SliderSettings = {
  min: -25,
  max: 25,
  step: 0.01,
  value: 0,
};

const Z: SliderSettings = {
  min: -25,
  max: 25,
  step: 0.01,
  value: 0,
};

const S: SliderSettings = {
  min: 0.5,
  max: 4,
  step: 0.01,
  value: 1,
};

export default { H, W, X, Y, Z, S };
