export enum ButtonType {
  H = "H",
  W = "W",
  Y = "Y",
  X = "X",
  Z = "Z",
  S = "S",
  ADD = "add",
  DELETE = "delete",
  CAMERA = "camera",
  HOME = "home",
  SAVE = "save",
  UNDO = "undo",
  REDO = "redo",
  MENU = "menu",
}
