import styled from 'styled-components';

const profileImage = '/profile.png';

export function CollapsedProfile() {
  return (
    <Container>
      <img
        alt="Profile"
        src={profileImage}
        style={{ height: 22, width: 120 }}
      />
    </Container>
  );
}

const Container = styled.div`
  // height: 80%;
`;
