import { Euler, useThree } from "@react-three/fiber";
import { MarquisCoat, MarquisType, Model } from "../utils/types";

import { useGesture } from "@use-gesture/react";
import { useState } from "react";

import MarquisWindow from "./MarquisWindow";
import MarquisWindowRoll from "./MarquisWindowRoll";
import MarquisWindowTerrace from "./MarquisWindowTerrace";

interface Props {
  templateMode: boolean;
  initialPosition: number[];
  rotation: Euler;
  model: Model;
  mobile: boolean;
  modelMoved: (position: number[]) => void;
}
const ModelView: React.FC<Props> = (props: Props) => {
  const [position, setPosition] = useState(props.initialPosition);
  const { size, viewport } = useThree();
  const aspect = size.width / viewport.width;
  const isMobile = props.mobile;

  const bind = useGesture({
    onDragStart: (state) => {},
    onDrag: ({ offset: [x, y], event }) => {
      if (props.templateMode) return;
      event.stopPropagation();
      props.modelMoved([x, y]);
      return setPosition([x, y, 0]);
    },
  },
    {
      drag: {
        from: [props.initialPosition[0], props.initialPosition[1]]
      }
    }
  );

  switch (props.model.marquisType) {
    case MarquisType.WINDOW:
      return <MarquisWindow {...bind()} {...props} position={[position[0], -position[1], isMobile ? 200 : 400]} />;
    case MarquisType.ROLL:
      return <MarquisWindowRoll {...bind()} {...props} position={[position[0], -position[1], isMobile ? 200 : 400]} />;
    case MarquisType.TERRACE:
      return <MarquisWindowTerrace {...bind()} {...props} position={[position[0], -position[1], isMobile ? 200 : 400]} />;
    default:
      return <MarquisWindow {...bind()} {...props} position={[position[0], -position[1], isMobile ? 200 : 400]} />;
  }
};

export default ModelView;
