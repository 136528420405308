// @ts-nocheck
import { useRef,useEffect } from "react";
import { Euler } from "@react-three/fiber";
import { Edges, useTexture } from "@react-three/drei";
import { DoubleSide } from "three";
import * as THREE from 'three';
interface Props {
  rotation: Euler;
  model: Model;
  position: number[];
}

let count = 1;
const idMap = new WeakMap();

const MarquisWindowRoll = (props: Props) => { 
  const groupRef = useRef();
  const {
    model: { scale, product, profile, marquisCoat, repeatTexture, selected, size, rotation, id },
  } = props;

  const profileColor = (n: number) => {
    switch (n) { 
      case 0:
        return '#000000';
      case 1:
        return '#5c5c5c';
      case 2:
        return '#e8e8e8';
      default:
        return '#000000';
    }
  };

  const width = size.w;
  const height = size.h;
  const depth = 80;

  const a = height / 2;
  const b = depth;
  const c = height / 2;

  const textureLoad = useTexture(product?.fabric_image ?? "");
  if (!repeatTexture.texture !== undefined) {
    repeatTexture.texture = textureLoad.clone();
  }

  const repeatX = repeatTexture.w / (product.fabric_width / 10);
  const offsetX = Math.ceil(repeatX) - repeatX;

  const tx = repeatTexture.texture;
  tx.wrapS = tx.wrapT = 1000;
  tx.offset.set(offsetX/2, 0);
  tx.repeat.x = repeatX;
  tx.repeat.y = 1;

  const tx2 = repeatTexture.texture;
  tx2.wrapS = tx2.wrapT = 1000;
  tx2.offset.set(offsetX/2, 0);
  tx2.repeat.x = repeatX;
  tx2.repeat.y = 0.1;

  useEffect(() => {
    if (groupRef.current) {
      var rotationXRad = THREE.MathUtils.degToRad(rotation.x);
      var rotationYRad = THREE.MathUtils.degToRad(rotation.y);
      var rotationZRad = THREE.MathUtils.degToRad(rotation.z);

      // Skapa en quaternion som representerar rotationen runt kubens egna axel
      var rotationQuaternionX = new THREE.Quaternion();
      rotationQuaternionX.setFromAxisAngle(new THREE.Vector3(0, 1, 0).normalize(), rotationXRad);

      var rotationQuaternionY = new THREE.Quaternion();
      rotationQuaternionY.setFromAxisAngle(new THREE.Vector3(0, 0, 1), rotationYRad);

      var rotationQuaternionZ = new THREE.Quaternion();
      rotationQuaternionZ.setFromAxisAngle(new THREE.Vector3(1, 0, 0).normalize(), rotationZRad);
      
      // Kombinera de tre quaternionerna genom att multiplicera dem i önskad ordning
      var rotationQuaternion = rotationQuaternionX.multiply(rotationQuaternionY).multiply(rotationQuaternionZ);

      // Skapa en separat quaternion som representerar den ursprungliga rotationen
      var initialRotationQuaternion = new THREE.Quaternion();
      initialRotationQuaternion.copy(rotationQuaternion);

      // Återställ kubens rotation till den ursprungliga rotationen
      groupRef.current.quaternion.copy(initialRotationQuaternion);

      // Rotera kuben med quaternionen
      groupRef.current.quaternion.multiply(rotationQuaternion);
    }
  }, [rotation.z, rotation.x, rotation.y]);

  return (
    <group {...props} ref={groupRef} scale={[scale, scale, scale]}>
      
      <mesh position={[0, 0, 0]} rotation={[0, 0, 0]} scale={[width, height, 1]} visible={selected}>
        <planeGeometry />
        <meshBasicMaterial color="#46ab44" side={DoubleSide} transparent opacity={0.2} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#46ab44" depthTest={false} />
        </Edges>
      </mesh>

      <mesh position={[0, -2, 0]} rotation={[0, 0, 0]} scale={[width, 6, 4]}>
        <boxGeometry/>
        <meshBasicMaterial color={profileColor(profile)} side={DoubleSide} metalness={1} />
      </mesh>

      <mesh position={[0, a/2, 0]} rotation={[0, 0, 0]} scale={[width, c, 1]}>
        <planeGeometry />
        <meshStandardMaterial map={tx} side={DoubleSide} repeat={[2,2]} />
      </mesh>
    </group>
  );
};

export default MarquisWindowRoll;
