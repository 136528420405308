import React, { createContext, useContext, useState, ReactNode, FunctionComponent } from 'react';
import { Product, MarquisPattern } from '../utils/types';
interface EditorContextType {
  selectedProduct: Product | null;
  setSelectedProduct: (product: Product) => void;
  selectedPattern: MarquisPattern;
  setSelectedPattern: (pattern: MarquisPattern) => void;
  selectedColors: number[];
  setSelectedColors: any;
}

interface EditorProviderProps {
  children: ReactNode;
}

const EditorContext = createContext<EditorContextType | undefined>(undefined);

const EditorProvider: FunctionComponent<EditorProviderProps> = ({ children }) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedPattern, setSelectedPattern] = useState<MarquisPattern>(MarquisPattern.ALL);
  const [selectedColors, setSelectedColors] = useState<any>([]);

  return (
    <EditorContext.Provider value={{
      selectedProduct,
      setSelectedProduct,
      selectedPattern,
      setSelectedPattern,
      selectedColors,
      setSelectedColors,
    }}>
      {children}
    </EditorContext.Provider>
  );
};

const useEditorContext = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditorContext måste användas inom en EditorProvider');
  }
  return context;
};

export { useEditorContext, EditorProvider };
