import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 95vh;
  max-height: 390px;
`;

export const ProductContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  height: calc(100dvh - 100px);
`;

export const TopContainer = styled.div`
  border-color: black;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  margin: 16px;
  margin-top: 8px;
  flex: 1;
`;
export const TopContainerTopRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  margin-bottom: 0px;
`;
export const TopContainerTopRowLeftTitle = styled.div`
  flex: 1;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-color: #d3d3d3;
  align-items: center;
`;
export const TopContainerTopRowRightPressable = styled.div`
  flex: 1;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #d3d3d3;
  align-items: center;
`;
export const TopContainerTopRowRightText = styled.p`
  margin: 8px;
  font-weight: bold;
`;
export const TopContainerTopRowLeftText = styled.p`
  margin: 8px;
`;
export const MidContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;
export const MidContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-height: 200px;
  flex: 1;
  border-color: #d3d3d3;
  justify-content: space-around;
  margin: 6px 0px;
`;
export const MidContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1;
`;
export const BoldText = styled.p<{ selected: boolean }>`
  font-weight: bold;
  font-size: 16px;
  text-decoration: ${props => (props.selected ? 'underline' : 'none')};
  text-underline-offset: 6px;
  cursor: pointer;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
`;

export const BottomContainerTouchable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 14px;
  height: 40px;
  margin: 4px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  font-weight: bold;
`;
export const BottomContainerTouchableClose = styled(BottomContainerTouchable)`
  color: #212529;
  border-style: solid;
`;
export const BottomContainerTouchableUse = styled(BottomContainerTouchable)`
  background-color: #212529;
`;

export const BottomContainerTouchableUseText = styled.div`
  color: white;
`;

export const TouchableImage = styled.div`
  align-items: center;
  margin: 12px;
`;
