import styled from 'styled-components';
import { memo } from 'react';
import { Product } from '../../utils/types';
import { useEditorContext } from '../../contexts/EditorContext';

interface ProductProps {
  productSelected: (product: Product, target: boolean, close: boolean) => void;
  product: Product;
}

const ProductItem = (props: ProductProps) => {
  const { product, productSelected } = props;
  const { selectedProduct, setSelectedProduct } = useEditorContext();

  const handleClick = (product: Product) => {
    setSelectedProduct(product)
  };

  const handleDoubleClick = () => {
    if(selectedProduct) productSelected(selectedProduct, false, false);
  };

  return (
    <TouchableProduct
      key={product.id}
      selected={selectedProduct?.id === product.id}
      onDoubleClick={handleDoubleClick}
      onClick={() => handleClick(product)}
      //onClick={() => handleClick(product)}
    >
      <img
        src={product.fabric_image}
        style={{ width: 70, height: 70 }}
        alt={product.title}
      />
      <p>{product.sku}</p>
    </TouchableProduct>
  );
}

const TouchableProduct = styled.div<{ selected: boolean }>`
  padding: 5px;
  font-size: 14px;
  line-height: 0;
  border-color: white;
  border-radius: 8px;
  height: 90px;
  border: ${props => (props.selected ? '1px solid black' : '1px solid white')};
`;

export default memo(ProductItem);
