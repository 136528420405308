import styled from "styled-components";


interface Props {
  type?: "primary" | "secondary";
  opacity?: number;
  selected?: boolean;
  onClick: Function;
  children: any;
  tooltipContent?: string | undefined;
  tooltipPlace?: "bottom" | "left" | "right" | "top" | undefined;
}
export default function IconButton({
  type = "primary",
  opacity = 55,
  selected,
  onClick,
  children,
  tooltipContent = undefined,
  tooltipPlace = undefined,
}: Props) {
  return (
    <Container onClick={() => onClick()} type={type} opacity={opacity} data-tooltip-id="my-tooltip" data-tooltip-content={tooltipContent} data-tooltip-place={tooltipPlace} data-tooltip-variant={`light`}>
      <InnerContainer type={type} selected={!!selected}>
        {children}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div<{ type: string, opacity: number, 'data-tooltip-id'?: string, 'data-tooltip-content'?: string, 'data-tooltip-place'?: string, 'data-tooltip-variant'?: string }>`
  background-color: ${(props) =>
    props.type === "primary" ? `rgba(0, 0, 0, ${props.opacity}%)` : "white"};
  backdrop-filter: blur(5px);
  border-radius: 30px;
  border-width: 2px;
  width: 40px;
  height: 40px;
  border-color: #212529;
  align-items: center;
  justify-content: center;
  margin: 4px;
  display: flex;
`;

const InnerContainer = styled.div<{ selected: boolean; type: string }>`
  border-width: ${(props) => (props.selected ? "2px" : "0px")};
  border-color: ${(props) => (props.selected ? "#212529" : "white")};
  border-style: solid;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 32px;
  height: 32px;
`;
