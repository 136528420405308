import styled from "styled-components";

export const TopBaseContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  margin: 16px;
  top: 0px;
`;

export const TopLeftContainer = styled(TopBaseContainer)`
  left: 0;
`;

export const TopRightContainer = styled(TopBaseContainer)`
  right: 0;
`;

export const BottomBaseContainer = styled.div`
  padding: 8px;
  position: fixed;
  height: 130px;
  border-top-right-radius: 8px;
  background-color: rgba(0, 0, 0, 70%);
  backdrop-filter: blur(2px);
`;

export const BottomLeftContainer = styled(BottomBaseContainer)`
  left: 0;
  bottom: 0;
  padding: 16px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 30px;
`;

export const BottomSliderContainer = styled(BottomBaseContainer)`
  bottom: 0;
  left: 70px;
  padding: 16px;
  border-top-left-radius: 8px;
  width: 10px;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextureSliderText = styled.div`
  color: white;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 0;
`;

export const TopTextureLengthSliderContainer = styled.div<{ 'data-tooltip-id'?: string, 'data-tooltip-content'?: string, 'data-tooltip-place'?: string, 'data-tooltip-variant'?: string }>`
  width: 300px;
  height: 32px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  padding: 0px 14px 16px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: rgba(0, 0, 0, 70%);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BottomRightContainer = styled(BottomBaseContainer)`
  right: 0;
  bottom: 0;
  height: 60px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 0px;
`;

export const InfoContainer = styled.div`
  color: white;
  font-size: 10px;
  width: 350px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  padding: 6px 6px 16px 6px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: rgba(0, 0, 0, 70%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
