import {
  Colors,
  DrawerMenuItemType,
  MarquisCoat,
  MarquisPattern,
  MarquisProfile,
  MarquisType,
  Model,
  Product,
} from "../../utils/types";
import { mdiWindowClose, mdiPlus } from "@mdi/js";

import { DrawerMenuItem } from "./drawerMenuItem";
import Icon from "@mdi/react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAppContext } from '../../contexts/AppContext';
import randomNumber from '../../utils/randomNumber';

const shortid = require("shortid");

interface DrawerMenuProps {
  isWeb: boolean;
  templateMode: boolean;
  visible: Boolean;
  onCloseClick: Function;
  selectedModel?: Model;
  osVersion?: string;
  addModel: (model: Model) => void;
  modelChanged: (model: Model) => void;
  templateChanged: (type: string, value: any) => void;
  orderProject: () => void;
}
export const DrawerMenu = (props: DrawerMenuProps) => {
  const { products } = useAppContext();
  const [selectedModel, setSelectedModel] = useState(
    props.selectedModel ? props.selectedModel : getDefaultMarquisModel()
  );
  const [lastModel, setLastModel] = useState<Model | undefined>(undefined);
  if (props.selectedModel?.id !== selectedModel.id && props.selectedModel)
    setSelectedModel(props.selectedModel);

  const [hasNotch, setHasNotch] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(-1);
  const closeMenuItem = () => setSelectedMenuItem(-1);
  const shouldItemBeVisible = (menuItem: number) =>
    selectedMenuItem === -1 || selectedMenuItem === menuItem;

  const updateModel = (model: Model) => {   
    setSelectedModel(model);
    setLastModel({ ...model, id: shortid.generate() });
    if (model.selected === true) {
      props.modelChanged(model);
    }
  };

  const updateTemplateModel = (type: string, value: any) => {   
    props.templateChanged(type, value);
  };

  const addModel = (model: Model) => {
    if (model.product?.fabric_image === undefined) return;
    const newModel: Model = {
      ...model,
      id: shortid.generate(),
      position: {
        x: randomNumber(-40, 40),
        y: randomNumber(-40, 40)
      }
    };
    setSelectedModel(newModel);
    props.addModel(newModel);
  };

  const menuItems = [
    ...(props.templateMode ? [] : [DrawerMenuItemType.MARQUIS]),
    DrawerMenuItemType.PATTERN,
    DrawerMenuItemType.COLOR,
    DrawerMenuItemType.MARQUIS_COAT,
    DrawerMenuItemType.PROFILE,
  ];

  useEffect(() => {
    if (props.selectedModel === undefined) {
      updateModel(lastModel || getDefaultMarquisModel());
    }
  }, [props.selectedModel]);

  useEffect(() => {
    const mql = window.matchMedia(
      "(min-device-width: 375px) and (max-device-width: 812px)"
    );
    setHasNotch(mql.matches);
  }, []);

  const closeDrawerMenu = () => {
    closeMenuItem();
    props.onCloseClick();
  };

  return props.visible ? (
    <>
      <CloseOnClick onClick={closeDrawerMenu} />
      <MenuFiller
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100dvh",
          width: hasNotch ? "35px" : "0px",
        }}
      ></MenuFiller>
      <Container osVersion={props.osVersion} hasNotch={hasNotch}>
        <CloseButton onClick={closeDrawerMenu}>
          <Icon size={1} path={mdiWindowClose} />
        </CloseButton>
        <MenuContainer osVersion={props.osVersion}>
          {menuItems.map((menuItem) => {
            const index = menuItems.findIndex((x) => x === menuItem);
            if (
              selectedModel.marquisType === MarquisType.ROLL &&
              menuItem === DrawerMenuItemType.MARQUIS_COAT
            )
              return <div key={index} />;
            return (
              <DrawerMenuItem
                key={index}
                type={menuItem}
                visible={shouldItemBeVisible(index)}
                expanded={selectedMenuItem === index}
                selectedMarquisType={selectedModel.marquisType}
                selectedMarquisPattern={selectedModel.marquisPattern}
                selectedProduct={selectedModel.product}
                onPress={() => {
                  if (selectedMenuItem === -1)
                    return setSelectedMenuItem(index);
                  closeMenuItem();
                }}
                onClose={() => {
                  closeMenuItem();
                }}
                marquisTypeChanged={(type) => {
                  updateModel({
                    ...selectedModel,
                    marquisType: type,
                  });
                  closeMenuItem();
                }}
                marquisPatternChanged={(pattern) => {
                  updateModel({ ...selectedModel, marquisPattern: pattern });
                  closeMenuItem();
                }}
                productSelected={(value) => {
                  if (props.templateMode) { 
                    updateTemplateModel("product", value);
                  } else { 
                    updateModel({ ...selectedModel, product: value });
                  }
                }}
                marquisCoatChanged={(value) => {
                  if (props.templateMode) { 
                    updateTemplateModel("marquisCoat", value);
                  } else { 
                    updateModel({ ...selectedModel, marquisCoat: value });
                  }
                }}
                marquisProfileChanged={(value) => {
                  if (props.templateMode) { 
                    updateTemplateModel("profile", value);
                  } else { 
                    updateModel({ ...selectedModel, profile: value });
                  }       
                }}
                selectedMarquisCoat={selectedModel.marquisCoat}
                selectedProfile={selectedModel.profile}
                templateMode={props.templateMode}
              />
            );
          })}
          {selectedMenuItem === -1 && (
            <>
              <Divider />

              <BottomMenuContainer>
                {!props.templateMode ? (
                  <BottomButton
                    onClick={() =>
                      updateModel({
                        ...getDefaultMarquisModel(),
                        // id: selectedModel.id,
                      })
                    }
                  >
                    <MenuItemTitleText>Rensa Val</MenuItemTitleText>
                  </BottomButton>
                ) : null}

                <BottomButton onClick={props.orderProject}>
                  <MenuItemTitleText>Beställ vävprov</MenuItemTitleText>
                </BottomButton>

                {!props.templateMode ? (
                  <AddModelButtonContainer
                    onClick={() => addModel(selectedModel)}
                  >
                    <AddModelButton onClick={() => props.onCloseClick()}>
                      <Icon size={1.5} color="white" path={mdiPlus} />
                    </AddModelButton>
                  </AddModelButtonContainer>
                ) : null}
              </BottomMenuContainer>
            </>
          )}
        </MenuContainer>
      </Container>
    </>
  ) : (
    <div />
  );

  function getDefaultMarquisModel(): Model {
    return {
      id: shortid.generate(),
      marquisType: MarquisType.WINDOW,
      marquisPattern: MarquisPattern.ALL,
      marquisCoat: MarquisCoat.STRAIT_COAT,
      product: products[0],
      profile: MarquisProfile.BLACK,
      position: {
        x: 0,
        y: 0,
      },
      scale: 1,
      rotation: {
        x: 0,
        y: 0,
        z: 0,
      },
      size: {
        w: 200,
        h: 240,
      },
      repeatTexture: {
        w: 120,
      },
      selected: true,
    };
  }
};

const CloseOnClick = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100vw;
`;

const MenuFiller = styled.div<{ hasNotch?: any }>`
  background-color: white;
`;

const Container = styled.div<{ osVersion?: string; hasNotch?: any }>`
  position: fixed;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  width: 400px;
  height: 100dvh;
  margin-right: ${(props) => props.hasNotch && `35px`};
  z-index: 10;
`;

const MenuContainer = styled.div<{ osVersion?: string; hasNotch?: any }>`
  background-color: white;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  flex: 1;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 16px;
`;
const CloseButton = styled.div`
  margin: 16px;
  display: flex;
  position: absolute;
  left: -65px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const Divider = styled.div`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 1px;
  background-color: #d3d3d3;
  margin-top: 16px;
`;

const BottomMenuContainer = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
`;

const BottomButton = styled.div`
  height: 40px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 5;
  border-color: #212529;
  color: #212529;
  border-radius: 8px;
  border-width: 1px;
  margin-left: 8px;
  margin-top: 16px;
  border-style: solid;
  cursor: pointer;
`;

const AddModelButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
  height: 40px;
  width: 40px;
  margin-left: 8px;
  margin-top: 16px;
  cursor: pointer;
`;

const AddModelButton = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid black;
  border-radius: 50%;
  padding: 2px;
  background-color: #212529;
`;
const MenuItemTitleText = styled.p`
  font-weight: bold;
  text-align: center;
`;
