const fileToBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => { 
     return resolve(reader.result);
  }
  reader.onerror = error => reject(error);
});

const downloadToBase64 = async (fileUrl: any) => { 
  try {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error('Något gick fel vid hämtning av filen');
    }

    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        if (event?.target?.result) {
          resolve(event.target.result);
        } else {
          reject(new Error('Kunde inte läsa filen som Base64'));
        }
      };
      reader.onerror = (error) => {
        reject(new Error('Fel vid inläsning av filen: ' + error));
      };
    });
  } catch (error) {
    console.error('Fel vid hämtning av fil:', error);
    throw error;
  }
};

export { fileToBase64, downloadToBase64 };
