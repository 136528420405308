export const mockData = {
    "count": 141,
    "filters": {
        "colors": {
            "count": 9,
            "items": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                },
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                },
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                },
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                },
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                },
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ]
        },
        "patterns": {
            "count": 4,
            "items": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                },
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                },
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                },
                {
                    "id": 90,
                    "name": "Struktur",
                    "slug": "struktur"
                }
            ]
        }
    },
    "products": [
        {
            "id": 6839,
            "sku": "5",
            "title": "Markisväv 5",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7020-B90G",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5.jpg",
            "last_updated": "2024-01-11 12:02:13",
            "hash": "108c4ef204b102a4d8d0aef9f973f794"
        },
        {
            "id": 6842,
            "sku": "7",
            "title": "Markisväv 7",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7020-G10Y",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-7.jpg",
            "last_updated": "2024-01-11 12:05:46",
            "hash": "8eaffd0e8c280ae19bcc137110d68a40"
        },
        {
            "id": 6845,
            "sku": "10",
            "title": "Markisväv 10",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5540-R70B",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-10.jpg",
            "last_updated": "2023-12-11 10:32:12",
            "hash": "8929a21f63c512bbace0ce28b68c1271"
        },
        {
            "id": 6848,
            "sku": "11",
            "title": "Markisväv 11",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 1580-R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-11.jpg",
            "last_updated": "2023-12-11 10:30:12",
            "hash": "3a335c2b25cdbe04651b58e2c4fd65e0"
        },
        {
            "id": 6851,
            "sku": "12",
            "title": "Markisväv 12",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 0560-Y20R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-12.jpg",
            "last_updated": "2023-12-11 10:31:26",
            "hash": "02c80699f90b2a81ee4b6f2b90e7962d"
        },
        {
            "id": 6854,
            "sku": "13",
            "title": "Markisväv 13",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 3560-R80B",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-13.jpg",
            "last_updated": "2023-12-11 10:32:42",
            "hash": "ad8ddf8849e3be676f00d544414c0661"
        },
        {
            "id": 6857,
            "sku": "15",
            "title": "Markisväv 15",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 1002 -G",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-15.jpg",
            "last_updated": "2023-12-11 10:32:50",
            "hash": "de92151b7a11f600d685619849ca2efa"
        },
        {
            "id": 6983,
            "sku": "15-1",
            "title": "Markisväv 15-1",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 2010-Y10R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-15_1.jpg",
            "last_updated": "2023-12-11 10:34:11",
            "hash": "8272f9b0086ce3a7bcca971a0cf7aa21"
        },
        {
            "id": 6986,
            "sku": "15-14",
            "title": "Markisväv 15-14",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 1502-Y50R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-15_14.jpg",
            "last_updated": "2023-12-11 10:34:20",
            "hash": "ca34ee207daa0b395d7d8d6328d0394c"
        },
        {
            "id": 6989,
            "sku": "15-79",
            "title": "Markisväv 15-79",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 2000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-15_79.jpg",
            "last_updated": "2023-12-11 10:34:55",
            "hash": "1e4348f76141ca34ab8df4cd56c2abd4"
        },
        {
            "id": 6992,
            "sku": "15-93",
            "title": "Markisväv 15-93",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 1005-Y",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-15_93.jpg",
            "last_updated": "2023-12-11 10:35:00",
            "hash": "baa8629fbe3777d0cd9ea0c12ed7c843"
        },
        {
            "id": 21937,
            "sku": "15-515",
            "title": "Markisväv 15-515",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 1000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2018/02/15-515-1.jpg",
            "last_updated": "2023-12-12 07:20:17",
            "hash": "ce0306773075f86ee57d35b3c66a7483"
        },
        {
            "id": 6860,
            "sku": "24",
            "title": "Markisväv 24",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 9000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-24.jpg",
            "last_updated": "2023-12-11 10:33:17",
            "hash": "720e4f28179d74d0dba39a7ba37a8592"
        },
        {
            "id": 6863,
            "sku": "33",
            "title": "Markisväv 33",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-33.jpg",
            "last_updated": "2023-12-11 10:36:11",
            "hash": "c5c747fafca95beefcd464bc5e10d848"
        },
        {
            "id": 6866,
            "sku": "35",
            "title": "Markisväv 35",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-35.jpg",
            "last_updated": "2023-12-11 10:36:19",
            "hash": "ab7f581abae5289b54fe21b318101d94"
        },
        {
            "id": 6869,
            "sku": "37",
            "title": "Markisväv 37",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-37.jpg",
            "last_updated": "2023-12-11 10:36:30",
            "hash": "b9e65161de9854876cb05a1e6a330aa1"
        },
        {
            "id": 6872,
            "sku": "52",
            "title": "Markisväv 52",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 3010-Y30R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-52.jpg",
            "last_updated": "2023-12-11 10:36:41",
            "hash": "1ab77b70149d413e7140109d2f8d5523"
        },
        {
            "id": 6875,
            "sku": "54",
            "title": "Markisväv 54",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 2060-Y40R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-54.jpg",
            "last_updated": "2023-12-12 07:00:26",
            "hash": "e8ec37c1ec5eee6cf25b11735d2e759f"
        },
        {
            "id": 6878,
            "sku": "56",
            "title": "Markisväv 56",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 1085-Y90R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-56.jpg",
            "last_updated": "2023-12-12 07:20:04",
            "hash": "267166593006b933f66da308a95abead"
        },
        {
            "id": 6881,
            "sku": "58",
            "title": "Markisväv 58",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 3040-Y20R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-58.jpg",
            "last_updated": "2023-12-11 10:36:54",
            "hash": "5126ea8b2f6e77a1dd8e46cd4a1c7d08"
        },
        {
            "id": 6887,
            "sku": "72",
            "title": "Markisväv 72",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                },
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 0585-Y60R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-72.jpg",
            "last_updated": "2023-12-11 10:48:06",
            "hash": "e676e9457b76b102606fc530a6f0f262"
        },
        {
            "id": 6890,
            "sku": "73",
            "title": "Markisväv 73",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5040-R10B",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-73.jpg",
            "last_updated": "2023-12-11 10:48:13",
            "hash": "66fc7a2c3f71a955fa09a58708225aba"
        },
        {
            "id": 6893,
            "sku": "75",
            "title": "Markisväv 75",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7020-R80B",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-75.jpg",
            "last_updated": "2023-12-11 10:48:23",
            "hash": "230cb8a146d46667ad493b7d3dec2040"
        },
        {
            "id": 6896,
            "sku": "79",
            "title": "Markisväv 79",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-79.jpg",
            "last_updated": "2023-12-11 10:48:29",
            "hash": "8bb48dd58427740f440089ee70bcbf06"
        },
        {
            "id": 6899,
            "sku": "81",
            "title": "Markisväv 81",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8508-Y80R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-81.jpg",
            "last_updated": "2023-12-11 10:48:36",
            "hash": "e84aeb5842d2b425af3f193c7671f326"
        },
        {
            "id": 6902,
            "sku": "84",
            "title": "Markisväv 84",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4050-Y90R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-84.jpg",
            "last_updated": "2023-12-11 10:48:46",
            "hash": "e636b0667e840555f9995f7aa56d1716"
        },
        {
            "id": 6905,
            "sku": "87",
            "title": "Markisväv 87",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 6030-R90B",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-87.jpg",
            "last_updated": "2023-12-11 10:48:53",
            "hash": "24ed72c84fafb39e45244292985ff4d1"
        },
        {
            "id": 6995,
            "sku": "94-15",
            "title": "Markisväv 94-15",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4502-Y",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-94_15.jpg",
            "last_updated": "2023-12-11 10:49:00",
            "hash": "65666a69adb406d94f98f700d9c1211d"
        },
        {
            "id": 6998,
            "sku": "94-97",
            "title": "Markisväv 94-97",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5500-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-94_97.jpg",
            "last_updated": "2023-12-11 10:50:01",
            "hash": "dc78106c86766ce634668e253a3631db"
        },
        {
            "id": 6911,
            "sku": "92",
            "title": "Markisväv 92",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8010-R70B",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-92.jpg",
            "last_updated": "2023-12-12 07:33:02",
            "hash": "59ab610ce0d36b9cc986da0c6493d24d"
        },
        {
            "id": 6914,
            "sku": "97",
            "title": "Markisväv 97",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7500-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-97.jpg",
            "last_updated": "2023-12-11 10:50:07",
            "hash": "0e40629d42c6e640a400d5488c1ed675"
        },
        {
            "id": 6917,
            "sku": "98",
            "title": "Markisväv 98",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 2070-R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-98.jpg",
            "last_updated": "2023-12-11 10:50:15",
            "hash": "0d36cea781eee8a5ff7b20026ba90561"
        },
        {
            "id": 6920,
            "sku": "107",
            "title": "Markisväv 107",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8000-N",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/107MG.png",
            "last_updated": "2023-12-11 10:50:28",
            "hash": "80f57db30cae36f2b879b1a5b6e8a97b"
        },
        {
            "id": 21945,
            "sku": "107-515",
            "title": "Markisväv 107-515",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8000-N",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2018/02/107-515MG.jpg",
            "last_updated": "2023-12-11 10:54:48",
            "hash": "c62a40114497e5d99b7d0efa1d740d5e"
        },
        {
            "id": 7013,
            "sku": "116-15",
            "title": "Markisväv 116-15",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-116_15.jpg",
            "last_updated": "2023-12-11 10:54:59",
            "hash": "a05e8defe57085434ceef5e55b58d220"
        },
        {
            "id": 6926,
            "sku": "242",
            "title": "Markisväv 242",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-242.jpg",
            "last_updated": "2023-12-11 10:55:08",
            "hash": "d863e5513cddb8253990b6c40295028e"
        },
        {
            "id": 7016,
            "sku": "407-11",
            "title": "Markisväv 407-11",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 6030-R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-407_11.jpg",
            "last_updated": "2023-12-11 10:55:29",
            "hash": "15c66a859b66dce9d7a4c9a6b89796de"
        },
        {
            "id": 7022,
            "sku": "407-16",
            "title": "Markisväv 407-16",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5010-G70Y",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-407_16.jpg",
            "last_updated": "2023-12-11 10:55:54",
            "hash": "8fd9dc4de6207d06897348614c0afe9d"
        },
        {
            "id": 7040,
            "sku": "407-51",
            "title": "Markisväv 407-51",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 2010-Y30R",
            "fabric_width": 600,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-407_51.jpg",
            "last_updated": "2024-01-15 08:42:10",
            "hash": "36fe5893b50801d05491238d69c85fc7"
        },
        {
            "id": 7043,
            "sku": "407-52",
            "title": "Markisväv 407-52",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 6010-Y10R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-407_52.jpg",
            "last_updated": "2023-12-11 10:56:58",
            "hash": "cc291f8f5cdafeda5384ba4027214c55"
        },
        {
            "id": 7055,
            "sku": "407-79",
            "title": "Markisväv 407-79",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 6502-B",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-407_79.jpg",
            "last_updated": "2023-12-11 10:57:38",
            "hash": "654e94e47def34aae4347d9758751bcb"
        },
        {
            "id": 7058,
            "sku": "407-81",
            "title": "Markisväv 407-81",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8005-Y20R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/407-81MG.png",
            "last_updated": "2023-12-11 10:57:52",
            "hash": "1d1f806d7b6a8d5a792e033eb4384ff2"
        },
        {
            "id": 7067,
            "sku": "407-94",
            "title": "Markisväv 407-94",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-407_94.jpg",
            "last_updated": "2023-12-11 10:58:09",
            "hash": "59595ef5f1e22a36939d8539dca46e3c"
        },
        {
            "id": 21949,
            "sku": "407-102",
            "title": "Markisväv 407-102",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 3010-Y30R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/407-102.jpg",
            "last_updated": "2023-12-11 10:58:29",
            "hash": "0c83667d730a0bceaa95d1dfe03efca2"
        },
        {
            "id": 7019,
            "sku": "407-151",
            "title": "Markisväv 407-151",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 6502-Y",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-407_151.jpg",
            "last_updated": "2023-12-11 10:58:44",
            "hash": "e2faee65f076bb9e4ca50ef51ab57395"
        },
        {
            "id": 7025,
            "sku": "407-305",
            "title": "Markisväv 407-305",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8010-B70G",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/407-305MG.png",
            "last_updated": "2024-01-11 12:07:43",
            "hash": "78189c5f7f498ded97dbb97ffeac9db1"
        },
        {
            "id": 7031,
            "sku": "407-324",
            "title": "Markisväv 407-324",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8502-B",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/407-324MG.png",
            "last_updated": "2023-12-11 10:59:10",
            "hash": "95884d0f843eb6f352120134df9246d9"
        },
        {
            "id": 21952,
            "sku": "407-326",
            "title": "Markisväv 407-326",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7502-Y",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/407-326MG.png",
            "last_updated": "2023-12-11 10:59:25",
            "hash": "e10d24debe3434c6caf7e01e560e66a5"
        },
        {
            "id": 7034,
            "sku": "407-373",
            "title": "Markisväv 407-373",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4550-Y90R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/407-373MG.png",
            "last_updated": "2023-12-11 13:00:59",
            "hash": "81965f6d643c268d17b6d237382a490d"
        },
        {
            "id": 7037,
            "sku": "407-392",
            "title": "Markisväv 407-392",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7020-R80B",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/407-392MG.jpg",
            "last_updated": "2023-12-11 13:01:56",
            "hash": "963dbbe09805c4a5b0cedaa9827d761c"
        },
        {
            "id": 7049,
            "sku": "407-727",
            "title": "Markisväv 407-727",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4010-Y10R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/407-727MG.png",
            "last_updated": "2023-12-11 11:02:00",
            "hash": "39a80913ea81494a2729fa19030b5fa0"
        },
        {
            "id": 7064,
            "sku": "407-926",
            "title": "Markisväv 407-926",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4005-Y20R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-407_926.jpg",
            "last_updated": "2023-12-11 11:02:07",
            "hash": "0a0175b4e70dc4f5d59398b17b716e6d"
        },
        {
            "id": 7076,
            "sku": "641-05",
            "title": "Markisväv 641-05",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-641_5.jpg",
            "last_updated": "2023-12-11 12:33:25",
            "hash": "5eed9a1396468a542a9c67ef87b930ea"
        },
        {
            "id": 7079,
            "sku": "641-10",
            "title": "Markisväv 641-10",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-641_10.jpg",
            "last_updated": "2023-12-11 12:33:38",
            "hash": "5f0cc1bd07cab9e0e72f006ad1839084"
        },
        {
            "id": 7082,
            "sku": "641-24",
            "title": "Markisväv 641-24",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-641_24.jpg",
            "last_updated": "2023-12-11 12:33:48",
            "hash": "8442f375b19509b9e4bb3e59b25bc974"
        },
        {
            "id": 7085,
            "sku": "641-84",
            "title": "Markisväv 641-84",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-641_84.jpg",
            "last_updated": "2023-12-11 12:34:00",
            "hash": "16f652b31cb30fa535dff39a043331bc"
        },
        {
            "id": 7088,
            "sku": "727-15",
            "title": "Markisväv 727-15",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-727_15.jpg",
            "last_updated": "2023-12-11 12:34:24",
            "hash": "0a761dbebefdf5eab0db4e6a0c927a8b"
        },
        {
            "id": 20770,
            "sku": "727-515",
            "title": "Markisväv 727-515",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/727-515MG.jpg",
            "last_updated": "2023-12-11 12:34:30",
            "hash": "a3c8b31e886048148be81179b36f6a00"
        },
        {
            "id": 6935,
            "sku": "787",
            "title": "Markisväv 787",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-787.jpg",
            "last_updated": "2023-12-11 12:35:42",
            "hash": "22b8eccf5873281f9caefe55bfc79aad"
        },
        {
            "id": 6938,
            "sku": "790",
            "title": "Markisväv 790",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/790MG.jpg",
            "last_updated": "2023-12-11 12:35:49",
            "hash": "c6d56397bbfc19a522dd9152320d795f"
        },
        {
            "id": 7094,
            "sku": "792-05",
            "title": "Markisväv 792-05",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/792_5MG.jpg",
            "last_updated": "2023-12-12 07:15:04",
            "hash": "c86077fc6c55e441ce6f4638db7c642c"
        },
        {
            "id": 7096,
            "sku": "792-24",
            "title": "Markisväv 792-24",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/792_24MG.jpg",
            "last_updated": "2023-12-12 07:15:12",
            "hash": "98f4d7b591bc84af3936a407228cdcec"
        },
        {
            "id": 7099,
            "sku": "792-73",
            "title": "Markisväv 792-73",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/792_73MG.jpg",
            "last_updated": "2023-12-12 07:15:18",
            "hash": "1369ae8ef5fd50360592007fa43243fe"
        },
        {
            "id": 7102,
            "sku": "792-75",
            "title": "Markisväv 792-75",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/792_75MG.jpg",
            "last_updated": "2023-12-12 07:15:25",
            "hash": "2afeac44dc65f4b6a6ef0635c5ef372b"
        },
        {
            "id": 7108,
            "sku": "792-84",
            "title": "Markisväv 792-84",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/792_84MG.jpg",
            "last_updated": "2023-12-12 07:15:30",
            "hash": "931a4626cd6a64d17a4763f57e08fb26"
        },
        {
            "id": 7114,
            "sku": "792-97",
            "title": "Markisväv 792-97",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/792_97MG.jpg",
            "last_updated": "2023-12-12 07:15:36",
            "hash": "1c3c89060ec03ca3ea8f61c4c38f1b31"
        },
        {
            "id": 7117,
            "sku": "792-98",
            "title": "Markisväv 792-98",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/markisguiden-792_98_2.jpg",
            "last_updated": "2023-12-12 07:16:25",
            "hash": "e619e5fd3208cc6ef8f9f7604c2837e9"
        },
        {
            "id": 7111,
            "sku": "792-926",
            "title": "Markisväv 792-926",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                },
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/792_926MG.jpg",
            "last_updated": "2023-12-12 07:16:52",
            "hash": "d6962173a85486b2101a742e431c41fe"
        },
        {
            "id": 20771,
            "sku": "873-17",
            "title": "Markisväv 873-17",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 1515-R80B",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/873-17MG.jpg",
            "last_updated": "2023-12-12 07:24:20",
            "hash": "bd82d1f26941eef27bbd9f865a4faa9f"
        },
        {
            "id": 20772,
            "sku": "873-78",
            "title": "Markisväv 873-78",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4005-G50Y",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/873-78MG.jpg",
            "last_updated": "2023-12-12 07:14:31",
            "hash": "17400548dcf712665651999b9c543048"
        },
        {
            "id": 32549,
            "sku": "873-87",
            "title": "Markisväv 873-87",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5020-R90B",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2020/08/873-87MG.jpg",
            "last_updated": "2023-12-12 07:24:27",
            "hash": "0bb563d38c90f54f5aca3a1f8e159453"
        },
        {
            "id": 7122,
            "sku": "873-926",
            "title": "Markisväv 873-926",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 6502-Y",
            "fabric_width": 120,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/873-926MG.jpg",
            "last_updated": "2023-12-11 12:36:10",
            "hash": "bac1817249fbc34583be9ef2cbcb6272"
        },
        {
            "id": 7125,
            "sku": "873-930",
            "title": "Markisväv 873-930",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 6005-Y50R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-873_930.jpg",
            "last_updated": "2023-12-12 07:22:32",
            "hash": "60f7b5b57ff158d01b467630e7c3e713"
        },
        {
            "id": 7134,
            "sku": "951-92",
            "title": "Markisväv 951-92",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                },
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-951_92.jpg",
            "last_updated": "2023-12-12 07:28:38",
            "hash": "a2b64ee5ab5909cac9624b55d35a5199"
        },
        {
            "id": 6944,
            "sku": "926",
            "title": "Markisväv 926",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 6005-Y20R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-926.jpg",
            "last_updated": "2023-12-12 07:33:22",
            "hash": "8b8f0d6d438eaeebb06754ac2f8a49ec"
        },
        {
            "id": 7143,
            "sku": "968-57",
            "title": "Markisväv 968-57",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/968-57MG.jpg",
            "last_updated": "2023-12-12 07:29:14",
            "hash": "52fdf7a135f5c496e49327b4e63ce416"
        },
        {
            "id": 7140,
            "sku": "968-426",
            "title": "Markisväv 968-426",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-968_426.jpg",
            "last_updated": "2023-12-11 12:36:20",
            "hash": "64a78c457654e3eeb73307b7b4e8ebab"
        },
        {
            "id": 7146,
            "sku": "968-15",
            "title": "Markisväv 986-15",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "2000-N",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/986-15MG.jpg",
            "last_updated": "2023-12-11 12:36:28",
            "hash": "c327630976f0e7ac31eed11e96f0872e"
        },
        {
            "id": 7149,
            "sku": "986-151",
            "title": "Markisväv 986-151",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "2005-Y20R",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/986-151MG.jpg",
            "last_updated": "2023-12-12 07:32:37",
            "hash": "6b0a015255a5b6e12bfe8b9d284e9626"
        },
        {
            "id": 7152,
            "sku": "986-727",
            "title": "Markisväv 986-727",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "4000-N",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/986-727MG.jpg",
            "last_updated": "2023-12-11 12:36:38",
            "hash": "fed91eba5227c3c5ef64e6b383802646"
        },
        {
            "id": 7196,
            "sku": "1081-24",
            "title": "Markisväv 1081-24",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 250,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/1081-24MG.jpg",
            "last_updated": "2023-12-11 12:51:09",
            "hash": "c244fe5fa483bca20453ff3137e0749a"
        },
        {
            "id": 20775,
            "sku": "1081-81",
            "title": "Markisväv 1081-81",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 250,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/1081-81MG.jpg",
            "last_updated": "2023-12-11 12:51:16",
            "hash": "f0861e0063b21375b1131e3f929eea6f"
        },
        {
            "id": 7202,
            "sku": "1081-84",
            "title": "Markisväv 1081-84",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 250,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/1081-84MG.jpg",
            "last_updated": "2023-12-11 12:51:27",
            "hash": "71a54849e6e5f14c2a683faa6fa57d97"
        },
        {
            "id": 7211,
            "sku": "1081-97",
            "title": "Markisväv 1081-97",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 250,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/1081-97MG.jpg",
            "last_updated": "2023-12-11 12:51:36",
            "hash": "0ff1d5e653b6ba661acc5771e79a7b2c"
        },
        {
            "id": 20773,
            "sku": "1081-305",
            "title": "Markisväv 1081-305",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 250,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/1081-305MG.jpg",
            "last_updated": "2023-12-11 12:52:30",
            "hash": "a278c0a54f3d9192d9ffb7f0157e1ec9"
        },
        {
            "id": 20774,
            "sku": "1081-392",
            "title": "Markisväv 1081-392",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 250,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/1081-392MG.jpg",
            "last_updated": "2023-12-11 12:52:40",
            "hash": "234a28fdb85fa652e48b55636c656003"
        },
        {
            "id": 20776,
            "sku": "1330-505",
            "title": "Markisväv 1330-505",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5000-N",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/1330-505MG-1.jpg",
            "last_updated": "2023-12-11 12:55:11",
            "hash": "9eef575930510e11fc017fc90060c337"
        },
        {
            "id": 44640,
            "sku": "1330-509",
            "title": "Markisväv 1330-509",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8505-Y80R",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/05/1330-509L2.jpg",
            "last_updated": "2023-12-11 12:55:19",
            "hash": "f79769140cc6385f8a659df6e828ede2"
        },
        {
            "id": 20777,
            "sku": "1330-512",
            "title": "Markisväv 1330-512",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7500-N",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/1330-512MG-1.jpg",
            "last_updated": "2023-12-11 12:55:25",
            "hash": "3af817cc7e52c17c81e7db86465c92a1"
        },
        {
            "id": 7220,
            "sku": "4215-24",
            "title": "Markisväv 4215-24",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8000-N",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/4215-24MG.jpg",
            "last_updated": "2023-12-11 12:57:56",
            "hash": "881ee3dff946b395d1a87be62e18f1b5"
        },
        {
            "id": 7223,
            "sku": "4215-81",
            "title": "Markisväv 4215-81",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8502-R",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/4215-81MG.jpg",
            "last_updated": "2023-12-11 12:58:01",
            "hash": "f254361002efaf1947c6597de7b329c4"
        },
        {
            "id": 7226,
            "sku": "4215-92",
            "title": "Markisväv 4215-92",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8010-R70B",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/4215-92MG.jpg",
            "last_updated": "2023-12-11 12:58:06",
            "hash": "c619c8e2aa696d5fe916eff892caa103"
        },
        {
            "id": 7229,
            "sku": "4215-97",
            "title": "Markisväv 4215-97",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5502-B",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/4215-97MG.jpg",
            "last_updated": "2023-12-11 12:58:19",
            "hash": "36ff456697441aa9dc962016e0ffa368"
        },
        {
            "id": 6961,
            "sku": "5167-02",
            "title": "Markisväv 5167-02",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                },
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5167_2.jpg",
            "last_updated": "2023-12-11 12:58:50",
            "hash": "43c0fdd3d16321255a0a28bef4dd01ec"
        },
        {
            "id": 6964,
            "sku": "5167-05",
            "title": "Markisväv 5167-05",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5167_5.jpg",
            "last_updated": "2023-12-11 12:58:56",
            "hash": "45f7e1ce66023264ff428cdd2b25c33c"
        },
        {
            "id": 6967,
            "sku": "5167-11",
            "title": "Markisväv 5167-11",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5167_11.jpg",
            "last_updated": "2023-12-11 12:59:01",
            "hash": "78ca44e2f65a8f665b2226001d4ba7dc"
        },
        {
            "id": 6970,
            "sku": "5167-12",
            "title": "Markisväv 5167-12",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5167_12.jpg",
            "last_updated": "2023-12-11 12:59:09",
            "hash": "91a6014782ab58886cd505a962fedd07"
        },
        {
            "id": 7244,
            "sku": "5167-97",
            "title": "Markisväv 5167-97",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5167_97.jpg",
            "last_updated": "2023-12-11 12:59:14",
            "hash": "be43a8e1cf810756dc54489da1b744c1"
        },
        {
            "id": 6979,
            "sku": "5173-12",
            "title": "Markisväv 5173-12",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5173_12.jpg",
            "last_updated": "2023-12-11 13:06:10",
            "hash": "32fb0b404ac07bfe4418e7e67565b244"
        },
        {
            "id": 7247,
            "sku": "5173-24",
            "title": "Markisväv 5173-24",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5173_24.jpg",
            "last_updated": "2023-12-11 13:06:16",
            "hash": "cd2ae2418774c1146b9b914a7464e05e"
        },
        {
            "id": 7250,
            "sku": "5173-75",
            "title": "Markisväv 5173-75",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                },
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5173_75.jpg",
            "last_updated": "2023-12-11 13:06:26",
            "hash": "c4ade47c2c97f30b9458cd632d10cf87"
        },
        {
            "id": 7256,
            "sku": "5173-97",
            "title": "Markisväv 5173-97",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/03/markisguiden-5173_97.jpg",
            "last_updated": "2023-12-11 13:06:34",
            "hash": "ebccff072f24f169d6237f8584601874"
        },
        {
            "id": 6950,
            "sku": "5372",
            "title": "Markisväv 5372",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/5372MG.jpg",
            "last_updated": "2023-12-12 07:06:36",
            "hash": "f632c93b0c89d09c63874fb94f4bcda4"
        },
        {
            "id": 6953,
            "sku": "5374",
            "title": "Markisväv 5374",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/5374MG.jpg",
            "last_updated": "2023-12-12 07:06:42",
            "hash": "2681c5cfc39f73915c3b3b8e9ab16748"
        },
        {
            "id": 7280,
            "sku": "5379-24",
            "title": "Markisväv 5379-24",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/5379-24MG-1.jpg",
            "last_updated": "2023-12-12 07:07:48",
            "hash": "196173a692be562f244ab69ca86d6be2"
        },
        {
            "id": 20781,
            "sku": "5380-24",
            "title": "Markisväv 5380-24",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8500-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5380-24MG.jpg",
            "last_updated": "2023-12-11 13:38:28",
            "hash": "b1f0d83cf92f01a34289805d28115f98"
        },
        {
            "id": 20780,
            "sku": "5380-107",
            "title": "Markisväv 5380-107",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7500-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5380-107MG.jpg",
            "last_updated": "2023-12-11 13:38:49",
            "hash": "40b8fba96a6a4ba675a0027770038967"
        },
        {
            "id": 20782,
            "sku": "5380-327",
            "title": "Markisväv 5380-327",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 3500-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5380-327MG.jpg",
            "last_updated": "2023-12-11 13:40:11",
            "hash": "fbde694ae12f1a4450c6652389227682"
        },
        {
            "id": 7283,
            "sku": "5380-426",
            "title": "Markisväv 5380-426",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                },
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4040-Y60R",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5380-426MG.jpg",
            "last_updated": "2023-12-11 13:40:18",
            "hash": "76e838a730dbf0c886534219333ea47d"
        },
        {
            "id": 20783,
            "sku": "5380-727",
            "title": "Markisväv 5380-727",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 6000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5380-727MG.jpg",
            "last_updated": "2023-12-11 13:40:25",
            "hash": "c89974bb5887688166c868ff5e579648"
        },
        {
            "id": 20784,
            "sku": "5380-97",
            "title": "Markisväv 5380-97",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5380-97MG.jpg",
            "last_updated": "2023-12-12 07:32:19",
            "hash": "d6ae3787c0996b7d6eea166b400d891d"
        },
        {
            "id": 20788,
            "sku": "5396-84",
            "title": "Markisväv 5396-84",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 75,
                    "name": "Röd",
                    "slug": "rod",
                    "color": "#992b2b",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5396-84MG22.jpg",
            "last_updated": "2023-12-11 13:42:17",
            "hash": "08c547f8117a4096508ec771c213be4e"
        },
        {
            "id": 20785,
            "sku": "5396-107",
            "title": "Markisväv 5396-107",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5396-107MG.jpg",
            "last_updated": "2023-12-11 13:42:25",
            "hash": "3b851eb9f3f4e5e48686e3fd3e0c72e8"
        },
        {
            "id": 20786,
            "sku": "5396-151",
            "title": "Markisväv 5396-151",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5396-151MG22.jpg",
            "last_updated": "2023-12-11 13:42:33",
            "hash": "129cdaaeca4f51f4d283c6f4a57cb7fb"
        },
        {
            "id": 20787,
            "sku": "5396-701",
            "title": "Markisväv 5396-701",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5396-701MG22.jpg",
            "last_updated": "2023-12-11 13:42:39",
            "hash": "1f87038dca36a89ef3491f8b9b8d1805"
        },
        {
            "id": 20789,
            "sku": "5396-929",
            "title": "Markisväv 5396-929",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5396-929MG22.jpg",
            "last_updated": "2023-12-11 13:42:46",
            "hash": "8c7537ac4a5572b2ddd64bb9ee963478"
        },
        {
            "id": 20791,
            "sku": "5398-78",
            "title": "Markisväv 5398-78",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5398-78MG.jpg",
            "last_updated": "2023-12-11 13:43:21",
            "hash": "19be7174cc5b34dd8d0545841fea09e7"
        },
        {
            "id": 20790,
            "sku": "5398-107",
            "title": "Markisväv 5398-107",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                },
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5398-107MG.jpg",
            "last_updated": "2023-12-12 07:09:46",
            "hash": "05f7eb33e0954469c34130d320688705"
        },
        {
            "id": 21988,
            "sku": "5400-24",
            "title": "Markisväv 5400-24",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2018/02/5400-24MG2.jpg",
            "last_updated": "2023-12-11 13:44:21",
            "hash": "ac9c6d151df44c24f96a8014f2df31ab"
        },
        {
            "id": 21991,
            "sku": "5400-81",
            "title": "Markisväv 5400-81",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2018/02/5400-81MG2.jpg",
            "last_updated": "2023-12-11 13:44:37",
            "hash": "31c29e637f911c701caa7a6383e5b968"
        },
        {
            "id": 21993,
            "sku": "5400-97",
            "title": "Markisväv 5400-97",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                },
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2018/02/5400-97MG2.jpg",
            "last_updated": "2023-12-11 13:44:56",
            "hash": "2c00ef816855b1f86da4ad02f91f34ba"
        },
        {
            "id": 21986,
            "sku": "5400-102",
            "title": "Markisväv 5400-102",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2018/02/5400-102MG2.jpg",
            "last_updated": "2023-12-11 13:45:08",
            "hash": "a83b08f013eefe74e8a11c5e5ca60afa"
        },
        {
            "id": 21987,
            "sku": "5400-107",
            "title": "Markisväv 5400-107",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2018/02/5400-107MG2.jpg",
            "last_updated": "2023-12-11 13:45:24",
            "hash": "11f7c0fda1636ac2f5ab0b29f5d6a467"
        },
        {
            "id": 21989,
            "sku": "5400-327",
            "title": "Markisväv 5400-327",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2018/02/5400-327MG_2.jpg",
            "last_updated": "2023-12-11 13:45:39",
            "hash": "782aebdd4adcbc0264b5dbe4204b7bed"
        },
        {
            "id": 21990,
            "sku": "5400-727",
            "title": "Markisväv 5400-727",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 240,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2018/02/5400-727MG2.jpg",
            "last_updated": "2023-12-11 13:45:48",
            "hash": "bc9347c8184c8373f20068e800ad0234"
        },
        {
            "id": 20778,
            "sku": "5356-102",
            "title": "Markisväv 5356-102",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5356-102MG.jpg",
            "last_updated": "2023-04-05 09:18:47",
            "hash": "4c2ba5aab73908c026c0f777415404e0"
        },
        {
            "id": 7262,
            "sku": "5356-58",
            "title": "Markisväv 5356-58",
            "patterns": [
                {
                    "id": 127,
                    "name": "Randig",
                    "slug": "randig"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                },
                {
                    "id": 76,
                    "name": "Gul",
                    "slug": "gul",
                    "color": "#fee425",
                    "color_label_black": false
                }
            ],
            "info": [
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Komposition: 100% spinnfärgad akryl",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 300,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2013/01/5356-58MG.jpg",
            "last_updated": "2023-04-05 08:55:35",
            "hash": "42a1433f3912d2a98fed07ac3a422320"
        },
        {
            "id": 20799,
            "sku": "5405-87",
            "title": "Markisväv 5405-87",
            "patterns": [
                {
                    "id": 85,
                    "name": "Blockrandig",
                    "slug": "blockrandig"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5405-87-1.jpg",
            "last_updated": "2023-12-12 07:10:05",
            "hash": "6b922287d199292bc220272fe961b4bf"
        },
        {
            "id": 20801,
            "sku": "5407-05",
            "title": "Markisväv 5407-05",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 73,
                    "name": "Grön",
                    "slug": "gron",
                    "color": "#116228",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8010-G10Y",
            "fabric_width": 200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-05mg-e1680698837729.jpg",
            "last_updated": "2024-01-11 12:06:42",
            "hash": "4cb9d9cec7a52741063dce842b85dd6a"
        },
        {
            "id": 20805,
            "sku": "5407-15",
            "title": "Markisväv 5407-15",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 77,
                    "name": "Vit",
                    "slug": "vit",
                    "color": "#ffffff",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 3000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-15-1.jpg",
            "last_updated": "2023-12-11 13:48:30",
            "hash": "6dd21ad3785be2e972dc0ff03140b6e1"
        },
        {
            "id": 20806,
            "sku": "5407-24",
            "title": "Markisväv 5407-24",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 78,
                    "name": "Svart",
                    "slug": "svart",
                    "color": "#000000",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8500-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-24-2.jpg",
            "last_updated": "2023-12-11 13:48:39",
            "hash": "13b9b554c20862544f4986bbb6c445d0"
        },
        {
            "id": 20810,
            "sku": "5407-81",
            "title": "Markisväv 5407-81",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 80,
                    "name": "Brun",
                    "slug": "brun",
                    "color": "#684c2c",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 8005-Y80R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-81-1.jpg",
            "last_updated": "2023-12-11 13:49:06",
            "hash": "4d3d3ded74fab0937ccffa6972c6ee22"
        },
        {
            "id": 20812,
            "sku": "5407-92",
            "title": "Markisväv 5407-92",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 74,
                    "name": "Blå",
                    "slug": "bla",
                    "color": "#163b9a",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7020-R80B",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-92-1.jpg",
            "last_updated": "2023-12-11 13:49:13",
            "hash": "53ec3eba2b5656d5f4bb2d1846c1f5a8"
        },
        {
            "id": 20802,
            "sku": "5407-102",
            "title": "Markisväv 5407-102",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 2005-Y40R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-102-1.jpg",
            "last_updated": "2023-12-12 07:05:06",
            "hash": "089d99de8dd557017e40aa5a2b578e22"
        },
        {
            "id": 20803,
            "sku": "5407-106",
            "title": "Markisväv 5407-106",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4005-Y50R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-106-1.png",
            "last_updated": "2023-05-16 08:50:34",
            "hash": "e59f903032b0b81ead615bee9a909877"
        },
        {
            "id": 20804,
            "sku": "5407-107",
            "title": "Markisväv 5407-107",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-107-1.jpg",
            "last_updated": "2023-04-05 13:03:47",
            "hash": "eeeff683888f9f0a0338ad1ad3b9dbe2"
        },
        {
            "id": 20807,
            "sku": "5407-306",
            "title": "Markisväv 5407-306",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4010-Y30R",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-306-1.jpg",
            "last_updated": "2023-04-05 13:03:21",
            "hash": "bfbd66beeddfef3226417d75f1b0be80"
        },
        {
            "id": 20808,
            "sku": "5407-397",
            "title": "Markisväv 5407-397",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 5000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-397-1.jpg",
            "last_updated": "2023-04-05 13:03:07",
            "hash": "0ef46312688f2f979da754486794d066"
        },
        {
            "id": 20809,
            "sku": "5407-727",
            "title": "Markisväv 5407-727",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 4000-N",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-727-1.jpg",
            "last_updated": "2023-04-05 13:02:54",
            "hash": "12525f3798943a00b78352aad148aa5f"
        },
        {
            "id": 20813,
            "sku": "5407-930",
            "title": "Markisväv 5407-930",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7502-Y",
            "fabric_width": 1200,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2017/09/5407-930-1.jpg",
            "last_updated": "2023-04-05 13:02:04",
            "hash": "4f35aef84892acf48f33c4c544394b55"
        },
        {
            "id": 45912,
            "sku": "5422-14",
            "title": "Markisväv 5422-14",
            "patterns": [
                {
                    "id": 84,
                    "name": "Enfärgad",
                    "slug": "enfargad"
                },
                {
                    "id": 90,
                    "name": "Struktur",
                    "slug": "struktur"
                }
            ],
            "colors": [
                {
                    "id": 79,
                    "name": "Beige",
                    "slug": "beige",
                    "color": "#cec0a1",
                    "color_label_black": false
                },
                {
                    "id": 81,
                    "name": "Grå",
                    "slug": "gra",
                    "color": "#b0b0b0",
                    "color_label_black": false
                }
            ],
            "info": [
                "Komposition: 100% spinnfärgad akryl",
                "Ljushärdighet: 7-8 på en 8:a gradig skala",
                "Tvättbarhet: Ljummen tvållösning (max 30º C)"
            ],
            "ncs": "S 7502-Y",
            "fabric_width": 600,
            "fabric_image": "https://sandatex.se/wp-content/uploads/2023/10/5422_14_MG.jpg",
            "last_updated": "2024-02-20 09:51:44",
            "hash": "ae2fad2265fbd4c89c082ae36c5e83a4"
        }
    ]
};