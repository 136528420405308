import { MarquisProfile } from '../../utils/types';
import styled from 'styled-components';
import {
  Container,
  TopContainer,
  TopContainerTopRow,
  TopContainerTopRowLeftTitle,
  TopContainerTopRowRightPressable,
  TopContainerTopRowLeftText,
  TopContainerTopRowRightText,
  MidContainer,
  MidContainerLeft,
  MidContainerRight,
  BoldText,
  BottomContainer,
  BottomContainerTouchable,
  BottomContainerTouchableClose,
  BottomContainerTouchableUse,
  BottomContainerTouchableUseText,
} from '../ExpandedStyles';

const marquisProfileBlackImage = '/marquis_profile_black.png';
const marquisProfileGreyImage = '/marquis_profile_grey.png';
const marquisProfileWhiteImage = '/marquis_profile_white.png';
interface Props {
  selectedProfile: MarquisProfile;
  marquisProfileChanged: (profile: MarquisProfile) => void;
  onClose: () => void;
}
export function ExpandedProfile(props: Props) {
  const TopRow = (props: Props) => {
    return (
      <TopContainerTopRow>
        <TopContainerTopRowLeftTitle>
          <TopContainerTopRowLeftText>Fönstermarkis</TopContainerTopRowLeftText>
        </TopContainerTopRowLeftTitle>
        <TopContainerTopRowRightPressable>
          <TopContainerTopRowRightText>Kappa</TopContainerTopRowRightText>
        </TopContainerTopRowRightPressable>
      </TopContainerTopRow>
    );
  };

  const MarquisTextButton = (p: { profile: MarquisProfile }) => {
    const { profile } = p;
    const text =
      profile === MarquisProfile.BLACK
        ? 'Svart'
        : profile === MarquisProfile.GREY
        ? 'Grå'
        : 'Vit';
    return (
      <TouchableText onClick={() => props.marquisProfileChanged(profile)}>
        <BoldText selected={props.selectedProfile === profile}>{text}</BoldText>
      </TouchableText>
    );
  };

  const Mid = (props: Props) => {
    return (
      <MidContainer>
        <MidContainerLeft>
          <TouchableImage
            onClick={() => props.marquisProfileChanged(MarquisProfile.BLACK)}
          >
            <Image src={marquisProfileBlackImage} />
          </TouchableImage>
          <TouchableImage
            onClick={() => props.marquisProfileChanged(MarquisProfile.GREY)}
          >
            <Image src={marquisProfileGreyImage} />
          </TouchableImage>
          <TouchableImage
            onClick={() => props.marquisProfileChanged(MarquisProfile.WHITE)}
          >
            <Image src={marquisProfileWhiteImage} />
          </TouchableImage>
        </MidContainerLeft>
        <MidContainerRight>
          <MarquisTextButton profile={MarquisProfile.BLACK} />
          <MarquisTextButton profile={MarquisProfile.GREY} />
          <MarquisTextButton profile={MarquisProfile.WHITE} />
        </MidContainerRight>
      </MidContainer>
    );
  };

  const Bottom = (props: Props) => {
    return (
      <BottomContainer>
        <BottomContainerTouchableClose onClick={props.onClose}>
          <p>Stäng</p>
        </BottomContainerTouchableClose>
        <BottomContainerTouchableUse onClick={() => props.onClose()}>
          <BottomContainerTouchableUseText>
            Använd
          </BottomContainerTouchableUseText>
        </BottomContainerTouchableUse>
      </BottomContainer>
    );
  };

  return (
    <Container>
      <TopContainer style={{ display: 'flex', flexDirection: 'column' }}>
        <TopRow {...props} />
        <Mid {...props} />
      </TopContainer>
      <Bottom {...props} />
    </Container>
  );
}

const TouchableImage = styled.div`
  align-items: center;
  margin: 8px;
`;

const TouchableText = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100px;
  resize: contain;
`;
