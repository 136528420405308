import React, { createContext, useContext, useState, ReactNode, FunctionComponent, Dispatch, SetStateAction } from 'react';
import { mockData } from "../utils/types/MockData";
import {
  Colors,
  Model,
  Product,
} from "../utils/types";

interface AppContextType {
  products: Product[];
  setProducts: (value: Product[]) => void;
  colors: Colors;
  setColors: (value: Colors) => void;
  objects: Model[];
  setObjects: Dispatch<SetStateAction<Model[]>>;
  renderer: any;
  setRenderer: any;
}

interface AppProviderProps {
  children: ReactNode;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

const AppProvider: FunctionComponent<AppProviderProps> = ({ children }) => {
  const [products, setProducts] = useState(mockData.products);
  const [colors, setColors] = useState(mockData.filters.colors);
  const [objects, setObjects] = useState<Model[]>([]);
  const [renderer, setRenderer] = useState<any>(null);

  return (
    <AppContext.Provider value={{
      products,
      setProducts,
      colors,
      setColors,
      objects,
      setObjects,
      renderer,
      setRenderer
    }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext måste användas inom en AppProvider');
  }
  return context;
};

export { useAppContext, AppProvider };
