import { MarquisCoat } from '../../utils/types';

const noCoatImage = '/no_coat.png';
const straitCoatImage = '/marquis_strait_coat.png';

interface Props {
  selectedMarquisCoat?: MarquisCoat;
}
export function CollapsedMarquisCoat(props: Props) {
  return (
    <div>
      <img
        alt="Marquis Coat"
        style={{ height: 22, width: 120 }}
        src={
          props.selectedMarquisCoat === MarquisCoat.STRAIT_COAT
            ? straitCoatImage
            : noCoatImage
        }
      />
    </div>
  );
}
