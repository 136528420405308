import styled from 'styled-components';
import { memo } from 'react';
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';
import { useSearchContext } from '../../contexts/SearchContext';

interface SearchBarProps { 
  isOpen: (status: boolean) => void;
}

const SearchBarContainer = (props: SearchBarProps) => {
  const { isOpen } = props;
  const { searchQuery, setSearchQuery } = useSearchContext();

  const closeSearch = () => {
    setSearchQuery("");
    isOpen(false);
  };

  return (
    <SearchBar>
      <input onChange={(e) => setSearchQuery(e.target.value)} type="text" value={searchQuery} />
      <CloseSearchBarButton onClick={ (e) => closeSearch() }>
        <Icon path={mdiCloseCircle} size={1} />  
      </CloseSearchBarButton>
    </SearchBar>
  );
}

const SearchBar = styled.div`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & input {
    border: 1px black solid;
    height: 34px;
    flex: 1 0;
    padding-left: 10px;
  }
`;

const CloseSearchBarButton = styled.button`
  flex: 0 0;
  margin-left: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export default memo(SearchBarContainer);





