import { MarquisPattern } from '../../utils/types';

interface Props {
  selectedPattern: MarquisPattern;
}
export function CollapsedPattern(props: Props) {
  const text =
    props.selectedPattern === MarquisPattern.SINGLE_COLOR
      ? 'Enfärgad'
      : props.selectedPattern === MarquisPattern.BLOCK_STRIPED
      ? 'Blockrandig'
      : props.selectedPattern === MarquisPattern.STRIPED
      ? 'Randig'
      : 'Alla';
  // TODO: Background
  return (
    <div>
      <p>{text}</p>
    </div>
  );
}
