import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import {
  ButtonType,
  Model,
  SliderSettings,
} from "./utils/types";
import { Suspense, useState, FunctionComponent, memo, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  mdiArrowLeftRight,
  mdiArrowUpDown,
  mdiContentSave,
  mdiHome,
  mdiInformation,
  mdiMenu,
  mdiPlus,
  mdiRotate360,
  mdiTrashCan,
  mdiRestore,
  mdiHistory,
  mdiImage,
  mdiResize,
  mdiDownload,
  mdiExportVariant,
} from "@mdi/js";

import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Direction } from "react-range/lib/types";
import { DrawerMenu } from "./components/DrawerMenu";
import Icon from "@mdi/react";
import IconButton from "./components/IconButton";
import WebSelectPreset from "./components/WebSelectPreset";
import OrderModal from "./components/OrderModal";
import ExportModal from "./components/ExportModal";
import ModelView from "./components/ModelView";
import { Project } from "./utils/types/Project";
import { Range } from "react-range";
import { getAbsoluteImage, getTemplate } from "./utils/templateBackgrounds";
import sliderSettings from "./utils/sliderSettings";
import { useEffect } from "react";
import "./fonts/Trenda-Regular.woff";
import { useAppContext } from './contexts/AppContext';
import { Tooltip } from 'react-tooltip';
import { OrthographicCamera, PerspectiveCamera } from '@react-three/drei';
import BackgroundImage from "./components/BackgroundImage";
import { downloadToBase64 } from "./utils/fileToBase64";
import randomNumber from './utils/randomNumber';

import {
  TopLeftContainer,
  TopRightContainer,
  BottomLeftContainer,
  BottomSliderContainer,
  TextureSliderText,
  TopTextureLengthSliderContainer,
  InfoContainer,
} from "./components/Styled";

const shortid = require("shortid");

const App: FunctionComponent = () => {
  const ROTATION_VALUE = 7;
  const appRef = useRef<HTMLDivElement | null>(null);
  const { setProducts, setColors, objects, setObjects, renderer, setRenderer } = useAppContext();
  const [image, setImage] = useState<string | undefined>("/background-house.jpg");
  const [isWeb, setIsWeb] = useState<boolean>(true);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);
  const [webselect, setWebselect] = useState<boolean>(false);
  const [openOrder, setOpenOrder] = useState<boolean>(false);
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [exportImage, setExportImage] = useState<null|string>(null);
  const [sliderValues, setSliderValues] = useState<SliderSettings>({
    min: 0,
    max: 1,
    step: 0.1,
    value: 0,
  });
  const [fabricSliderValue, setFabricSliderValue] = useState(120);
  const [sliderButtonSelected, setSliderButtonSelected] = useState(
    ButtonType.Y
  );
  const [showDrawerMenu, setShowDrawerMenu] = useState(false);
  const [osVersion, setOsVersion] = useState("");
  const [project, setProject] = useState<Project | undefined>(undefined);
  const [templateMode, setTemplateMode] = useState<boolean>(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [useOrbitControl, setUseOrbitControl] = useState(false);
  const [isOrthographic, setIsOrthographic] = useState(true);

  function CustomCamera({ isOrthographic }: { isOrthographic: any }) {
    const { camera } = useThree();
  
    useEffect(() => {
      if (isOrthographic) {
        camera.updateProjectionMatrix();
      } else {

      }
    }, [isOrthographic, camera]);

    return isOrthographic ? (
      <OrthographicCamera makeDefault position={[0, 0, 500]} near={0.1} far={1000} />
    ) : (
      <PerspectiveCamera makeDefault position={[0, 0, 500]} />
    );
  }

  const loadImage = async (imageData: any) => {
    switch (true) {
      case imageData.startsWith('data:image/jpeg;base64,'):
        return imageData;
      case imageData.length > 200 && (!imageData.endsWith('.jpg') || !imageData.endsWith('.jpeg')):
        return `data:image/jpeg;base64,${imageData}`;
      case /^\d+$/.test(imageData):
        return downloadToBase64(getAbsoluteImage(imageData));
      default:
        return "/template_house_1.png";
    }
  };

  const loadWebImage = async (imageData: any) => {
    setImage(await loadImage(imageData));

    if (/^\d+$/.test(imageData)) {
      const template: any = getTemplate(imageData);
      setProject({
        id: shortid.generate(),
        models: template.modelData,
      });
      setObjects(template.modelData);
      setIsOrthographic(true);
      setTemplateMode(true); /* UTVECKLING - Sätt denna till false om du vill flytta på markiserna */
    } else {
      setObjects((models) => []);
      setIsOrthographic(false);
      setTemplateMode(false);
    }

    setWebselect(false);
  };

  useEffect(() => {
    const fetchProducts = () => {
      fetch("https://sandatex.se/wp-json/api/markisguiden/products")
        .then((res) => res.json())
        .then((data) => { 
          if (data.products && data.filters.colors) {
            setColors(data.filters.colors);
            setProducts(data.products);
          }
        });
    };
    return fetchProducts();
  }, []);

  /* Endast för debug, skruvar på scenen  */
  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (event.key == "c") setUseOrbitControl(true);
    });
    window.addEventListener("keyup", (event) => {
      if (event.key == "c") setUseOrbitControl(false);
    });
  }, []);


  useEffect(() => {
    const getWebSelectFromQueryParams = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const webSelectValue = queryParams.get('webselect');
      if (!webSelectValue || webSelectValue != 'off') {
        setIsWeb(true);
        setWebselect(true);
      } else {
        setIsWeb(false);
        setWebselect(false);
      }
    };
    getWebSelectFromQueryParams();
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.addEventListener("message", async (message) => {
      try {
        const data = message.data;
        if (data.code && data.code === 200) {
          //setColors(data.msg.filters.colors);
          //setProducts(data.msg.products);
        } else if (data.code && data.code === 201) {
          setImage(await loadImage(data.img));
        } else if (data.code && data.code === 202) {
          loadWebImage(data.img);
        } else if (data.code && data.code === 203) {
          setProject(data.msg);
          setObjects(data.msg.models);
        } else if (data.code && data.code === 204) {
          setOsVersion(data.msg);
        } else if (data.code && data.code === 205) {
          console.log("saving" + data.msg);
          if (data.msg !== "PROJECT_SAVED") return;
          const successToast = () =>
            toast.success("Projekt sparat!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              toastId: 2,
            });
          successToast();
          toast.clearWaitingQueue();
        } else if (data.code && data.code === 206) {
          console.log("error");
          if (data.msg !== "Project_SAVED_ERROR") return;
          const errorToast = () =>
            toast.error("Project saved error!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              toastId: 3,
            });
          errorToast();
          toast.clearWaitingQueue();
        }
      } catch (error) {
        console.log("error", error);
      }
    });
  }, []);

  const toggleMenu = () => {
    setShowDrawerMenu((value) => !value);
  };

  const goBack = () => {
    toast(customMsg, {
      position: "top-center",
      closeOnClick: false,
      autoClose: false,
      hideProgressBar: true,
      toastId: 1,
    });
  };

  const customMsg = ({ closeToast }: any) => (
    <div>
      <p> Vill du spara ditt projekt innan du lämnar?</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <button
          style={{ fontSize: "18px", padding: "4px" }}
          onClick={() => {
            saveProjectToServer();
            window.ReactNativeWebView.postMessage("GO_HOME");
          }}
        >
          Ja
        </button>
        <button
          style={{ fontSize: "18px", padding: "4px" }}
          onClick={() => {
            window.ReactNativeWebView.postMessage("GO_HOME");
          }}
        >
          Nej
        </button>
      </div>
    </div>
  );

  const sliderValueChanged = (value: number) => {
    setSliderValues({ ...sliderValues, value: value });
    switch (sliderButtonSelected) {
      case ButtonType.X:
        setObjects((models: Model[]) =>
          models.map((x) => {
            if (x.selected) {
              x.rotation = {
                x: value,
                y: x.rotation.y,
                z: x.rotation.z,
              };
            }
            return x;
          })
        );
        break;
      case ButtonType.Y:
        setObjects((models) =>
          models.map((x) => {
            if (x.selected) {
              x.rotation = {
                y: value,
                x: x.rotation.x,
                z: x.rotation.z,
              };
            }
            return x;
          })
        );
        break;
      case ButtonType.Z:
        setObjects((models) =>
          models.map((x) => {
            if (x.selected) {
              x.rotation = {
                y: x.rotation.y,
                x: x.rotation.x,
                z: value,
              };
            }
            return x;
          })
        );
        break;
      case ButtonType.S:
        setObjects((models) =>
          models.map((x) => {
            if (x.selected) {
              x.scale = value;
            }
            return x;
          })
        );
        break;
      case ButtonType.W:
        setObjects((models) =>
          models.map((x) => {
            if (x.selected) {
              x.size = {
                w: value,
                h: x.size.h,
              };
            }
            return x;
          })
        );
        break;
      case ButtonType.H:
        setObjects((models) =>
          models.map((x) => {
            if (x.selected) {
              x.size = {
                w: x.size.w,
                h: value,
              };
            }
            return x;
          })
        );
    }
  };

  const fabricSliderValueChanged = (value: number) => {
    setObjects((models) => {
      return models.map((x) => {
        if (x.selected && x.product) {
          x.repeatTexture = {
            w: value,
          };
        }
        return x;
      });
    });
  };

  const modelMoved = (modelId: string, position: number[]) => {
    if (!templateMode) { 
      setObjects((models) =>
        models.map((x) => {
          if (x.id === modelId) {
            x.position = { x: position[0], y: position[1] };
            if (x.selected === false) {
              x.selected = true;
              setFabricSliderValue(x.repeatTexture.w);
            }
          } else {
            x.selected = false;
          }
          return x;
        })
      );
    }
  };

  const sliderButtonChanged = (button: ButtonType) => {
    setSliderValues({
      ...sliderValues,
      value: objects.find((x) => x.selected)
        ? objects.find((x) => x.selected)!!.rotation.x / ROTATION_VALUE
        : 0,
    });
    setSliderButtonSelected(button);
    if (objects.find((x) => x.selected) === undefined) return;
    setSliderButtonSelected(button);

    switch (button) {
      case ButtonType.W:
        setSliderValues({
          ...sliderSettings[button],
          value: objects.find((x) => x.selected)!!.size.w,
        });
        break;

      case ButtonType.H:
        setSliderValues({
          ...sliderSettings[button],
          value: objects.find((x) => x.selected)!!.size.h,
        });
        break;

      case ButtonType.X:
        setSliderValues({
          ...sliderSettings[button],
          value: objects.find((x) => x.selected)!!.rotation.x,
        });
        break;
      case ButtonType.Y:
        setSliderValues({
          ...sliderSettings[button],
          value: objects.find((x) => x.selected)!!.rotation.y,
        });
        break;

      case ButtonType.Z:
        setSliderValues({
          ...sliderSettings[button],
          value: objects.find((x) => x.selected)!!.rotation.z,
        });
        break;

      case ButtonType.S:
        setSliderValues({
          ...sliderSettings[button],
          value: objects.find((x) => x.selected)!!.scale,
        });
        break;
    }
  };
  const marquisLengthText = () => {
    const selectedObject = objects.find((x) => x.selected);
    if (!selectedObject)
      return <p style={{ float: "left" }}>Markisens bredd</p>;
    //const countLength = selectedObject!!.repeatTexture.w;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: "14px" }}>Markisens bredd</p>
        <p style={{ fontSize: "14px", fontWeight: "bolder" }}>
          {fabricSliderValue && Math.round(fabricSliderValue)} cm
        </p>
      </div>
    );
  };

  const removeSelectedModel = () => {
    setObjects((models) => models.filter((x) => !x.selected));
  };

  const resetSelectedModel = () => {
    const resetRotation = { x: 0, y: 0, z: 0 };

    setObjects((models) =>
      models.map((x) => {
        if (x.selected) {
          x.rotation = resetRotation;
        }
        return x;
      })
    );
    switch (sliderButtonSelected) {
      case ButtonType.X:
        sliderValueChanged(0);
        break;
      case ButtonType.Y:
        sliderValueChanged(0);
        break;
      case ButtonType.Z:
        sliderValueChanged(0);
        break;
      default:
        break;
    }
  };

  const duplicateModel = () => {
    const selectedObj = objects.find((x) => x.selected === true);
    const newObj: any = { ...selectedObj };
    newObj.id = shortid.generate();
    newObj.selected = false;
    newObj.position = {
      x: randomNumber(-40, 40),
      y: randomNumber(-40, 40),
    };
    setObjects((models) => [...models, newObj]);
  };

  const saveProjectToServer = () => {
    const canvasElement = appRef.current?.querySelector('canvas');
    const imgData = (canvasElement) ? canvasElement.toDataURL("image/jpeg") : null;

    const projectToSave: Project = project
      ? {
          ...project,
          models: objects,
          thumb: imgData,
        }
      : {
          id: shortid.generate(),
          models: objects,
          thumb: imgData,
        };
    setProject(projectToSave);
    if (isWeb) console.log(projectToSave);
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ code: "SAVE_PROJECT", project: projectToSave })
    );
  };

  const orderProject = () => {

    if (isWeb) { 
      setOpenOrder(true);
      return;
    }

    const projectToOrder: Project = project
      ? {
          ...project,
          models: objects,
        }
      : {
          id: shortid.generate(),
          models: objects,
        };

    window.ReactNativeWebView.postMessage(
      JSON.stringify({ code: "ORDER_PROJECT", project: projectToOrder })
    );
  };

  useEffect(() => {
    const selectedCheck = objects.find((x) => x.selected === true);

    if (selectedCheck?.selected) {
      setHideButtons(true);
    } else {
      setHideButtons(false);
    }
  }, [objects]);


  function CaptureButton() {
    const captureImage = () => {
      if (appRef.current) {
        const canvasElement = appRef.current?.querySelector('canvas');
        if (canvasElement) {
          // Nu har du tillgång till det underliggande <canvas>-elementet
          const imgData = canvasElement.toDataURL("image/jpeg");
  
          // För att direkt ladda ner bilden:
          const a = document.createElement("a");
          a.href = imgData;
          a.download = "markiser.jpg";
          a.click();
        }
      }
    };
  
    return (
      <IconButton
        tooltipContent="Ladda ner"
        tooltipPlace="right"
        onClick={captureImage}
      >
        <Icon color={"white"} size={1} path={mdiDownload} />
      </IconButton>
    );
  }

  function exportPdf() { 
    const canvasElement = appRef.current?.querySelector('canvas');
    if (canvasElement) {
      const imgData = canvasElement.toDataURL("image/jpeg");
      setExportImage(imgData);
      setOpenExport(true);
    }
  }
  
  return (
    <div
      className="App"
      ref={appRef}
      style={
        {
          //backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          //backgroundSize: `"${isWeb ? '100%' : '80%'}"`,
          backgroundSize: 'cover',
          backgroundPosition: "center",
        }
      }
    >
      <div
        style={{
          zIndex: -10,
          position: 'absolute',
          width: '100%',
          height: '100%',
          //backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(10px)',
        }}
      ></div>

      {webselect ? <WebSelectPreset image={image} callback={loadWebImage} /> : null}
      {openOrder ? <OrderModal image={image} objects={objects} openOrder={setOpenOrder} /> : null}
      {openExport ? (
        <ExportModal image={exportImage} objects={objects} openOrder={setOpenExport} />
      ) : null}

      <Canvas
        flat
        gl={{ preserveDrawingBuffer: true }}
        //camera={{ fov: 100, near: 0.1, far: 1000, position: [0, 0, 500] }}
        //orthographic={isOrthographic} // templateMode
        style={{ touchAction: 'none', pointerEvents: 'all' }}
        onPointerMissed={(e) => {
          setObjects((models: Model[]) =>
            models.map((x) => {
              x.selected = false;
              return x;
            }),
          );
        }}
      >
        <CustomCamera isOrthographic={isOrthographic} />
        <ambientLight intensity={1} />
        <Suspense fallback={null}>
          {objects.map((x) => {
            return (
              <ModelView
                templateMode={templateMode}
                mobile={!webselect ? true : false}
                key={x.id}
                model={x}
                initialPosition={[x.position.x, x.position.y, 100]}
                rotation={[x.rotation.x, x.rotation.y, 0]}
                modelMoved={(position) => modelMoved(x.id, position)}
              />
            );
          })}
        </Suspense>

        {image && image.startsWith('data:image') ? <BackgroundImage image={image} /> : null}
        <OrbitControls enabled={useOrbitControl} />
      </Canvas>

      <TopLeftContainer>
        {isWeb ? (
          <>
            <IconButton
              tooltipContent="Nytt projekt"
              tooltipPlace="right"
              onClick={() => setWebselect(true)}
            >
              <Icon color={"white"} size={1} path={mdiImage} />
            </IconButton>
            <CaptureButton />
            {/* UTVECKLING - KNAPP ENDAST FÖR DEBUG NÄR MAN GÖR PROJEKT TENPLATES             
            <IconButton onClick={() => console.log(project) }>
                <Icon color={"white"} size={1} path={mdiInformation} />
            </IconButton>
            KNAPP ENDAST FÖR DEBUG NÄR MAN GÖR PROJEKT TENPLATES */}
          </>
        ): (
          <>
            <IconButton onClick={goBack}>
              <Icon color={"white"} size={1} path={mdiHome} />
            </IconButton>
            {/* <IconButton onClick={testLog}>
              <Icon color={"white"} size={1} path={mdiCamera} />
            </IconButton> */}
            <IconButton onClick={saveProjectToServer}>
              <Icon color={"white"} size={1} path={mdiContentSave} />
            </IconButton>
            <IconButton onClick={() => setShowTooltip( !showTooltip )}>
                <Icon style={{ opacity: showTooltip ? 1 : 0.5 }} color={"white"} size={1} path={mdiInformation} />
            </IconButton>
          </>
        )}
        {objects.length > 0 ? (
          <IconButton onClick={exportPdf}>
            <Icon color={"white"} size={1} path={mdiExportVariant} />
          </IconButton>
        ):null}
      </TopLeftContainer>
      {hideButtons && (
        <TopTextureLengthSliderContainer>
          <TextureSliderText>{marquisLengthText()}</TextureSliderText>
          <Range
            step={10}
            min={50}
            max={700}
            onFinalChange={(values: number[]) => {
              setFabricSliderValue(values[0]);
              fabricSliderValueChanged(values[0]);
            }}
            onChange={(values: number[]) => {
              setFabricSliderValue(values[0]);
              //fabricSliderValueChanged(values[0]);
            }}
            direction={Direction.Right}
            values={[fabricSliderValue]}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  width: "100%",
                  height: "2px",
                  backgroundColor: "#ccc",
                  color: "#ccc",
                  marginTop: 2,
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  backgroundColor: "#ffffff",
                  outlineWidth: 1,
                  outlineColor: "#212529",
                }}
              />
            )}
          />
        </TopTextureLengthSliderContainer>
      )}

      <TopRightContainer>
        {hideButtons && (
          <>
            <IconButton
              tooltipContent="Ny markis"
              tooltipPlace="left"
              onClick={duplicateModel}
            >
              <Icon color={"white"} size={1} path={mdiPlus} />
            </IconButton>
            <IconButton
              tooltipContent="Radera"
              tooltipPlace="bottom"
              onClick={removeSelectedModel}
            >
              <Icon color={"white"} size={1} path={mdiTrashCan} />
            </IconButton>
          </>
        )}
        <IconButton
          onClick={toggleMenu}
        >
          <Icon color={"white"} size={1} path={mdiMenu} />
        </IconButton>
      </TopRightContainer>

      {hideButtons && (
        <>
          <BottomLeftContainer>
            <IconButton
              type="secondary"
              onClick={resetSelectedModel}
              tooltipContent="Radera och börja om"
              tooltipPlace="right"
            >
              <Icon size={1} path={mdiHistory} />
            </IconButton>
            <IconButton
              type="secondary"
              tooltipContent="Rotera runt markisens axel"
              tooltipPlace="right"
              onClick={() => sliderButtonChanged(ButtonType.Y)}
              selected={sliderButtonSelected === ButtonType.Y}
            >
              <Icon size={1} path={mdiRestore} rotate={90} />
            </IconButton>
            <IconButton
              type="secondary"
              tooltipContent="Rotera vågrätt"
              tooltipPlace="right"
              onClick={() => sliderButtonChanged(ButtonType.X)}
              selected={sliderButtonSelected === ButtonType.X}
            >
              <Icon size={1} path={mdiRotate360} />
            </IconButton>
            <IconButton
              type="secondary"
              tooltipContent="Rotera lodrätt"
              tooltipPlace="right"
              onClick={() => sliderButtonChanged(ButtonType.Z)}
              selected={sliderButtonSelected === ButtonType.Z}
            >
              <Icon size={1} path={mdiRotate360} rotate={90} />
            </IconButton>
            <IconButton
              type="secondary"
              tooltipContent="Öka/minska bredd"
              tooltipPlace="right"
              onClick={() => sliderButtonChanged(ButtonType.W)}
              selected={sliderButtonSelected === ButtonType.W}
            >
              <Icon size={1} path={mdiArrowLeftRight} />
            </IconButton>
            <IconButton
              type="secondary"
              tooltipContent="Öka/minska höjd"
              tooltipPlace="right"
              onClick={() => sliderButtonChanged(ButtonType.H)}
              selected={sliderButtonSelected === ButtonType.H}
            >
              <Icon size={1} path={mdiArrowUpDown} />
            </IconButton>

            <IconButton
              type="secondary"
              tooltipContent="Skala storlek"
              tooltipPlace="right"
              onClick={() => sliderButtonChanged(ButtonType.S)}
              selected={sliderButtonSelected === ButtonType.S}
            >
              <Icon size={1} path={mdiResize} />
            </IconButton>

          </BottomLeftContainer>
          <BottomSliderContainer>
            <Range
              step={sliderValues.step}
              min={sliderValues.min}
              max={sliderValues.max}
              onChange={(values: number[]) => {
                sliderValueChanged(values[0]);
              }}
              direction={Direction.Up}
              values={[sliderValues.value]}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "100%",
                    width: "2px",
                    backgroundColor: "#ccc",
                    color: "#ccc",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "24px",
                    width: "24px",
                    borderRadius: "50%",
                    backgroundColor: "#ffffff",
                    outlineWidth: 1,
                    outlineColor: "#212529",
                  }}
                />
              )}
            />
          </BottomSliderContainer>
        </>
      )}
      {hideButtons && (
        <TopTextureLengthSliderContainer
          data-tooltip-id="my-tooltip"
          data-tooltip-place={`bottom`}
          data-tooltip-variant={`light`}
          data-tooltip-content={`När du väljer randig markis, ställ in\n bredd för rätt mönsterrapportering`}
        >
          <TextureSliderText>{marquisLengthText()}</TextureSliderText>
          <Range
            step={1}
            min={50}
            max={700}
            onFinalChange={(values: number[]) => {
              setFabricSliderValue(values[0]);
              fabricSliderValueChanged(values[0]);
            }}
            onChange={(values: number[]) => {
              setFabricSliderValue(values[0]);
              //fabricSliderValueChanged(values[0]);
            }}
            direction={Direction.Right}
            values={[fabricSliderValue]}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  width: "100%",
                  height: "2px",
                  backgroundColor: "#ccc",
                  color: "#ccc",
                  marginTop: 2,
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  backgroundColor: "#ffffff",
                  outlineWidth: 1,
                  outlineColor: "#212529",
                }}
              />
            )}
          />
        </TopTextureLengthSliderContainer>
      )}
      {/* {hideButtons && (
        <BottomRightContainer>
          <IconButton
            type="secondary"
            onClick={() => setShowFabricSlider((value) => !value)}
          >
            <Icon size={1} path={mdiArrowLeftRight} />
          </IconButton>
        </BottomRightContainer>
      )} */}
      <DrawerMenu
        isWeb={isWeb}
        templateMode={templateMode}
        visible={showDrawerMenu}
        onCloseClick={() => setShowDrawerMenu(false)}
        selectedModel={objects.find((x) => x.selected)}
        osVersion={osVersion}
        addModel={(model: Model): void => {
          setFabricSliderValue(model.repeatTexture.w);
          setObjects((models) => [
            ...models.map((x) => {
              x.selected = false;
              return x;
            }),
            model,
          ]);
        }}
        modelChanged={(model: Model): void => {
          setObjects((models) =>
            models.map((x) => {
              if (x.id === model.id) {
                return model;
              }
              return x;
            })
          );
        }}
        templateChanged={(type: string, value: any) => {
          setObjects((models) =>
            models.map((model) => {
              if(type=="marquisCoat") model.marquisCoat = value;
              if (type == "product") model.product = value;
              if (type == "profile") model.profile = value;
              return model;
            })
          );
        }}
        orderProject={orderProject}
      />
      <InfoContainer>
        <span>Var vänlig observera att detta är en simulerad version av en färdig produkt.</span>
        <span>För en korrekt bedömning av väven beställ hem ett gratis fysiskt väv prov.</span>
      </InfoContainer>
      <ToastContainer limit={1} />
      {showTooltip ? <Tooltip id="my-tooltip" /> : null}
    </div>
  );
}

export default memo(App);
